import React from "react";
import IndexHeader from "../components/PageHeader/IndexHeader";
import Service from "./IndexSections/ServicesHome"
import WeDo from "./IndexSections/WeDo";
import AboutUsHome from "./IndexSections/AboutUsHome";
import Technologes from "./IndexSections/Technologes";
import WorkHere from "./IndexSections/WorkHere";
import Testimonial from "./IndexSections/Testimonials";
import Services from "./IndexSections/Services";
import News from "./IndexSections/News";
import Video from "./IndexSections/Video"
import { Helmet } from "react-helmet";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
class Index extends React.Component {
  componentDidMount() {
    document.body.classList.toggle("index-page");
    window.scrollTo(10, 10);

  }
  componentWillUnmount() {
    document.body.classList.toggle("index-page");
  }
  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Annexlogics System Pvt. Ltd.</title>
          <meta name="description" content="AnnexLogics is one of the top web and mobile app development company globally. We have all types of solution to satisfy the modern business era demands." />

        </Helmet>
        <div className="wrapper">
          <IndexHeader />
          <Service />
          <WeDo />
          <AboutUsHome />
          <Video />
          <Technologes />
          <WorkHere />
          <Testimonial />
          <Services />
          <News />
        </div>
      </>
    );
  }
}

export default Index;
