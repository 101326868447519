function teamList(state ={status:null , message:""  },action){
    switch(action.type){
        case "TEAM_LIST_SUCCESS":
      return {
        ...state,
        status: "SUCCESS",
        message:action.resultData
      }
      case "TEAM_LIST_FAILED":
      return {
        ...state,
        status: "FAILED",
        message:action.resultData
      };

    default:
      return state;
    }
}

export default teamList;