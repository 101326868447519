function subscribe(state = { status: null, message: "" }, action) {
    switch (action.type) {
        case "ADD_SUBSCRIBER":
            return {
                status: null,
                message:""
            }
        case "SUBSCRIBER_SUCCESS":
            return {
                ...state,
                status: "SUCCESS",
                message: action.resultData
            }
        case "SUBSCRIBER_FAILED":
            return {
                ...state,
                status: "FAILED",
                message: action.resultData
            };

        default:
            return state;
    }
}

export default subscribe;