import { combineReducers } from "redux"
import demo from "./demo"
import addressList from "./AddressList";
import lead from "./lead";
import caseList from "./CaseList";
import clientList from "./ClientList";
import teamList from "./TeamList";
import testimonialList from "./TestimonialList";
import servicesList from "./ServicesList";
import subscribe from "./Subscribe";
import casestudydetails from "./CaseDetail";
const rootReducer = combineReducers({
    demo,
    addressList,
    lead,
    caseList,
    clientList,
    teamList,
    testimonialList,
    servicesList,
    subscribe,
    casestudydetails

})
export default rootReducer;