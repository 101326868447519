import React, { useEffect, useState, useMemo } from 'react'
import { Container } from "reactstrap";
import {
    Row,
    Col,
    Carousel,
    CarouselItem,
    CarouselIndicators,
} from "reactstrap";
import OwlCarousel from 'react-owl-carousel';
import { TestimonialList, } from "../../action/index"
import { connect } from 'react-redux'
import { LargeLoader } from 'comman/loader';

function Testimonials(props) {
    const [testimonialLoad, setTestimonialLoad] = useState(true)
    const [testimonialData, setTestimonialData] = useState([])
    const responsive = {
        0: {
            items: 1,
        },
        768: {
            items: 1,
        },
        1000: {
            items: 1,
        },
        autoplaySpeed: 4000,

    }
    useEffect(() => {
        props.dispatchList()
    }, [])

    useEffect(() => {

        if (props.testimonialStatus === "SUCCESS") {
            setTestimonialLoad(false)
            setTestimonialData(props.testimonialData.data)
        }
    }, [props.testimonialStatus, props.testimonialData])

    
    const slider = useMemo(() => {
        return (<Col lg="7" md="12">
        <span className="iq-objects-03"><img src={require("assets/img/testi_m.png")} className="img-fluid topBottom"  alt=""/></span>
            {testimonialLoad ? <Row>
                <LargeLoader style="mt-4" />
            </Row> :
                <OwlCarousel loop={true} autoplay={true} mouseDrag={true} nav={false} dots={true} dotData={true} responsive={responsive} center={true}    autoplaySpeed={'1000'} autoplayTimeout={'4000'} items={1} >
                    {testimonialData.map((item, index) => {
                        return (

                            <div className="iq-testimonial " key={index}>
							<div className="testimonial-info">								
							<div className="testimonial-info-img">								
                                <img src={require("assets/img/qu_img.png")} className="img-fluid "  alt=""/>  

                                </div>                              
								<div className="testimonial-name">
									<h5 className="content">{item.name}</h5>
									<span className="sub-title"><span className="content-sub mr-2 ml-2">-</span>{item.position}</span>
								</div>
							</div>
							<p>{item.description}</p>
						</div>

                           
                        )
                    })}
                </OwlCarousel>
            }
        </Col>
        )
    }, [testimonialData])
    return (
        <div className="testimonial-bg testimonial-style owl-carousel">

<div className="iq-waves">
<div className="waves wave-1"></div>
<div className="waves wave-2"></div>
<div className="waves wave-3"></div>
</div>
            <Container>
                <Row className="align-items-center">
                    <Col lg="5" md="12" className="testimonial-text">
                        <div className="mb-0">
                            <h2 className="testimonial-title">Client <span>Testimonials </span></h2>
                            <p className="testimonial-p">Here is what keeps us motivated, words from our customers.</p>
                        </div>
                    </Col>
                    
                     {slider}
                </Row>
            </Container>
           
        </div>
    )
}

const mapStateToProps = state => ({
    testimonialStatus: state.testimonialList.status,
    testimonialData: state.testimonialList.message

})

const mapDispatchToProps = dispatch => ({
    dispatchList: data => dispatch(TestimonialList(data)),

})
export default connect(mapStateToProps, mapDispatchToProps)(Testimonials);