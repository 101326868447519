import React, { Component, useEffect, useState } from 'react'
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { LargeLoader } from 'comman/loader';
import { Container, Row, Col } from 'reactstrap'

export function Services() {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(false);
    }, [loading])

    const handleClick = e => {
        setLoading(false);
    }

    return (
        <div className="service-main">
            <Container>
                {loading ? <Row >
                    <LargeLoader style="mt-5" />
                </Row> :
                    <Row>
                        <Col lg="12" md="12">
                            <Tabs forceRenderTabPanel={true}>
                                <TabList>
                                    <Tab onClick={handleClick}>
                                        <img src={require("assets/img/svg/services/travel.svg")} className="img-fluid" alt="" />
                                        <h5>Travel</h5>
                                    </Tab>
                                    <Tab>
                                        <img src={require("assets/img/svg/services/transport.svg")} className="img-fluid" alt="" />
                                        <h5>Transport</h5>
                                    </Tab>
                                    <Tab>
                                        <img src={require("assets/img/svg/services/job-career.svg")} className="img-fluid" alt="" />
                                        <h5>Job/Career</h5>

                                    </Tab>
                                    <Tab>
                                        <img src={require("assets/img/svg/services/bank.svg")} className="img-fluid" alt="" />
                                        <h5>Banking</h5>
                                    </Tab>
                                    <Tab>
                                        <img src={require("assets/img/svg/services/business.svg")} className="img-fluid" alt="" />
                                        <h5>Business</h5>
                                    </Tab>
                                    <Tab>
                                        <img src={require("assets/img/svg/services/food.svg")} className="img-fluid" alt="" />
                                        <h5>Food</h5>
                                    </Tab>
                                    <Tab>
                                        <img src={require("assets/img/svg/services/real-estate.svg")} className="img-fluid" alt="" />
                                        <h5>Real Estate</h5>
                                    </Tab>
                                    <Tab>
                                        <img src={require("assets/img/svg/services/entertainment.svg")} className="img-fluid" alt="" />
                                        <h5>Entertainment</h5>
                                    </Tab>
                                    <Tab>
                                        <img src={require("assets/img/svg/services/education.svg")} className="img-fluid" alt="" />
                                        <h5>Education</h5>
                                    </Tab>
                                    <Tab>
                                        <img src={require("assets/img/svg/services/sports.svg")} className="img-fluid" alt="" />
                                        <h5>Sports</h5>
                                    </Tab>
                                    <Tab>
                                        <img src={require("assets/img/svg/services/healthcare.svg")} className="img-fluid" alt="" />
                                        <h5>Healthcare</h5>
                                    </Tab>
                                    <Tab>
                                        <img src={require("assets/img/svg/services/online-dating.svg")} className="img-fluid" alt="" />
                                        <h5>Dating</h5>
                                    </Tab>
                                </TabList>
                                <TabPanel>
                                    <Row className="services-detail align-items-center fade show ">
                                        <Col lg="6" md="12">
                                            <img src={require("assets/img/services/trvl_vctor.webp")} className="img-fluid" alt="" />
                                        </Col>
                                        <Col lg="6" md="12" className="md-mt-5">
                                            <h4 className="services-title">Travel App Development</h4>
                                            <p>Today, every piece of travel industry jigsaw is connected by technology.
                                                We help you enter this huge industry with the help of technology. Your unique
                                                idea combined with our technology experience can definitely boost the growth
                                                of your business.</p>
                                            <ul className="list-unstyled services-list" >
                                                <li>
                                                    <i className="flaticon-double-check"></i>
                                                    <p>Payment gateway integration</p>
                                                </li>
                                                <li>
                                                    <i className="flaticon-double-check"></i>
                                                    <p>travel planner</p>
                                                </li>
                                                <li>
                                                    <i className="flaticon-double-check"></i>
                                                    <p>reservation facility</p>
                                                </li>
                                                <li>
                                                    <i className="flaticon-double-check"></i>
                                                    <p>push notifications</p>
                                                </li>
                                                <li>
                                                    <i className="flaticon-double-check"></i>
                                                    <p>GPS & Location</p>
                                                </li>
                                                <li>
                                                    <i className="flaticon-double-check"></i>
                                                    <p>search functionality with filters</p>
                                                </li>
                                                <li>
                                                    <i className="flaticon-double-check"></i>
                                                    <p>reviews and recommendations</p>
                                                </li>
                                            </ul>
                                        </Col>
                                    </Row>
                                </TabPanel>


                                <TabPanel>
                                    <Row className="services-detail align-items-center">
                                        <Col lg="6" md="12">
                                            <img src={require("assets/img/services/trans_vactor.webp")} className="img-fluid" alt="" />
                                        </Col>
                                        <Col lg="6" md="12" className="md-mt-5">
                                            <h4 className="services-title">Logistics/Transportation App Development</h4>
                                            <p>Improve on efficiencies to reduce your operational costs with our
                                                Ready-to-Deploy logistics and transportation solutions.</p>
                                            <ul className="list-unstyled services-list" >
                                                <li>
                                                    <i className="flaticon-double-check"></i>
                                                    <p>Fleet Management</p>
                                                </li>
                                                <li>
                                                    <i className="flaticon-double-check"></i>
                                                    <p>On-Demand Logistics App</p>
                                                </li>
                                                <li>
                                                    <i className="flaticon-double-check"></i>
                                                    <p>Freight Management</p>
                                                </li>
                                                <li>
                                                    <i className="flaticon-double-check"></i>
                                                    <p>Dispatch & Delivery</p>
                                                </li>
                                                <li>
                                                    <i className="flaticon-double-check"></i>
                                                    <p>Warehouse Management</p>
                                                </li>

                                            </ul>
                                        </Col>
                                    </Row>
                                </TabPanel>

                                <TabPanel>
                                    <Row className="services-detail align-items-center">
                                        <Col lg="6" md="12">
                                            <img src={require("assets/img/services/jobs.webp")} className="img-fluid" alt="" />
                                        </Col>
                                        <Col lg="6" md="12" className="md-mt-5">
                                            <h4 className="services-title">Job/Career App Development</h4>
                                            <p>Help people find their dream jobs and companies find their star employees
                                                and allow our team to help you design and develop the job portal with
                                                unique proposition and features to</p>
                                            <p>Annex Logics team provide job portal app development, job search app
                                                development and online job portal development services for Android and iPhone</p>
                                            <p>Proficient Annex team design and develop very attractive job portals
                                                that helps people find their dream job effortlessly.</p>
                                        </Col>
                                    </Row>
                                </TabPanel>

                                <TabPanel>
                                    <Row className="services-detail align-items-center">
                                        <Col lg="6" md="12">
                                            <img src={require("assets/img/services/banking_vctor.webp")} className="img-fluid" alt="" />
                                        </Col>
                                        <Col lg="6" md="12" className="md-mt-5">
                                            <h4 className="services-title">Banking App Development</h4>
                                            <p>Mobile app development for the banking industry is challenging because
                                                of the security considerations. Help us handle all the technological
                                                challenges in your journey to make a dent in the fintech world</p>

                                        </Col>
                                    </Row>
                                </TabPanel>

                                <TabPanel>
                                    <Row className="services-detail align-items-center">
                                        <Col lg="6" md="12">
                                            <img src={require("assets/img/services/business_vctor.webp")} className="img-fluid" alt="" />
                                        </Col>
                                        <Col lg="6" md="12" className="md-mt-5">
                                            <h4 className="services-title">Business App Development</h4>
                                            <p>We provide customized solutions to professional service providers and businesses to better
                                                organize their resources and streamline their processes to attain maximum efficiency.</p>
                                            <ul className="list-unstyled services-list" >
                                                <li>
                                                    <i className="flaticon-double-check"></i>
                                                    <p>Accounts Management Software</p>
                                                </li>
                                                <li>
                                                    <i className="flaticon-double-check"></i>
                                                    <p>Book-keeping</p>
                                                </li>
                                                <li>
                                                    <i className="flaticon-double-check"></i>
                                                    <p>Resource Management</p>
                                                </li>


                                            </ul>
                                        </Col>
                                    </Row>
                                </TabPanel>

                                <TabPanel>
                                    <Row className="services-detail align-items-center">
                                        <Col lg="6" md="12">
                                            <img src={require("assets/img/services/food_vctr.webp")} className="img-fluid" alt="" />
                                        </Col>
                                        <Col lg="6" md="12" className="md-mt-5">
                                            <h4 className="services-title">Food App Development</h4>
                                            <p>Want to manage your restaurant or got an idea related to food
                                                delivery? Serve your customers with delicious dishes and help us serve the technology.</p>
                                            <ul className="list-unstyled services-list" >
                                                <li>
                                                    <i className="flaticon-double-check"></i>
                                                    <p>Food delivery app</p>
                                                </li>
                                                <li>
                                                    <i className="flaticon-double-check"></i>
                                                    <p>Smart kitchen dashboard UI</p>
                                                </li>
                                                <li>
                                                    <i className="flaticon-double-check"></i>
                                                    <p>Waiter management system</p>
                                                </li>
                                                <li>
                                                    <i className="flaticon-double-check"></i>
                                                    <p>Manager dashboard</p>
                                                </li>
                                                <li>
                                                    <i className="flaticon-double-check"></i>
                                                    <p>customer table management</p>
                                                </li>
                                                <li>
                                                    <i className="flaticon-double-check"></i>
                                                    <p>waiter allotment</p>
                                                </li>
                                            </ul>
                                        </Col>
                                    </Row>
                                </TabPanel>

                                <TabPanel>
                                    <Row className="services-detail align-items-center">
                                        <Col lg="6" md="12">
                                            <img src={require("assets/img/services/real_est.webp")} className="img-fluid" alt="" />
                                        </Col>
                                        <Col lg="6" md="12" className="md-mt-5">
                                            <h4 className="services-title">Real Estate App Development</h4>
                                            <p>Real Estate is one of the largest industries in the world. Being the largest
                                                industry it attracts cut-throat competition. Launch your innovative real estate
                                                platform with the help of our veteran developers to stand out of the crowd. We
                                                provide customized software solutions for rental agencies, real estate builders
                                                and developers, or agents to automate and grow their business globally.</p>

                                        </Col>
                                    </Row>
                                </TabPanel>

                                <TabPanel>
                                    <Row className="services-detail align-items-center">
                                        <Col lg="6" md="12">
                                            <img src={require("assets/img/services/enter.webp")} className="img-fluid" alt="" />
                                        </Col>
                                        <Col lg="6" md="12" className="md-mt-5">
                                            <h4 className="services-title">Entertainment App Development</h4>
                                            <p>Annex Logics offers development of customized media and entertainment mobile
                                                apps and websites which are designed to give your brand the technological
                                                edge over the ever-growing crowd of entertainment mobile apps.</p>
                                            <ul className="list-unstyled services-list" >
                                                <li>
                                                    <i className="flaticon-double-check"></i>
                                                    <p>Audio & Video</p>
                                                </li>
                                                <li>
                                                    <i className="flaticon-double-check"></i>
                                                    <p>Live Streaming</p>
                                                </li>
                                                <li>
                                                    <i className="flaticon-double-check"></i>
                                                    <p>ScreenShot blocking</p>
                                                </li>
                                                <li>
                                                    <i className="flaticon-double-check"></i>
                                                    <p>Text & GPS based facilities</p>
                                                </li>
                                                <li>
                                                    <i className="flaticon-double-check"></i>
                                                    <p>In-App Purchases</p>
                                                </li>
                                                <li>
                                                    <i className="flaticon-double-check"></i>
                                                    <p>Payment gateway integration</p>
                                                </li>
                                                <li>
                                                    <i className="flaticon-double-check"></i>
                                                    <p>Push Notifications</p>
                                                </li>
                                                <li>
                                                    <i className="flaticon-double-check"></i>
                                                    <p>Social Media Integration</p>
                                                </li>
                                            </ul>
                                        </Col>
                                    </Row>
                                </TabPanel>

                                <TabPanel>
                                    <Row className="services-detail align-items-center">
                                        <Col lg="6" md="12">
                                            <img src={require("assets/img/services/edu_vector.webp")} className="img-fluid" alt="" />
                                        </Col>
                                        <Col lg="6" md="12" className="md-mt-5">
                                            <h4 className="services-title">Education App Development</h4>
                                            <p>Exclusive classroom education is a thing of the past. The expansion of
                                                the internet in the classrooms and the upward trend of online education
                                                has empowered kids with smartphones, tablets, and iPads. Improve institutional
                                                technology infrastructure and the learning experience of students across the
                                                globe with your idea and the help of our educational app developers to deliver
                                                creative, robust and adaptive eLearning apps and solutions.</p>

                                        </Col>
                                    </Row>
                                </TabPanel>

                                <TabPanel>
                                    <Row className="services-detail align-items-center">
                                        <Col lg="6" md="12">
                                            <img src={require("assets/img/services/sports_vctor.webp")} className="img-fluid" alt="" />
                                        </Col>
                                        <Col lg="6" md="12" className="md-mt-5">
                                            <h4 className="services-title">Sports / Fitness App Development</h4>
                                            <p>Empower your sports or fitness app idea with our experienced team of developers.
                                                Let us help you overcome market challenges and build a unique solution for your
                                                end-users. For fitness apps, we make use of Internet Of Things, Gyroscope,
                                                Barometer, Accelerometer, and Wearables.</p>

                                        </Col>
                                    </Row>
                                </TabPanel>

                                <TabPanel>
                                    <Row className="services-detail align-items-center">
                                        <Col lg="6" md="12">
                                            <img src={require("assets/img/services/doc_vctr.webp")} className="img-fluid" alt="" />
                                        </Col>
                                        <Col lg="6" md="12" className="md-mt-5">
                                            <h4 className="services-title">Healthcare App Development</h4>
                                            <p>Mobile apps and wearables has revolutionized the entire
                                                health and fitness industry.</p>
                                            <ul className="list-unstyled services-list" >
                                                <li>
                                                    <i className="flaticon-double-check"></i>
                                                    <p>Health reports</p>
                                                </li>
                                                <li>
                                                    <i className="flaticon-double-check"></i>
                                                    <p>doctor prescription</p>
                                                </li>
                                                <li>
                                                    <i className="flaticon-double-check"></i>
                                                    <p>hospital appointments</p>
                                                </li>
                                                <li>
                                                    <i className="flaticon-double-check"></i>
                                                    <p>sleep quality analysis</p>
                                                </li>
                                                <li>
                                                    <i className="flaticon-double-check"></i>
                                                    <p>heart rate measurement</p>
                                                </li>
                                            </ul>

                                        </Col>
                                    </Row>
                                </TabPanel>

                                <TabPanel>
                                    <Row className="services-detail align-items-center">
                                        <Col lg="6" md="12">
                                            <img src={require("assets/img/services/dating_vctor.webp")} className="img-fluid" alt="" />
                                        </Col>
                                        <Col lg="6" md="12" className="md-mt-5">
                                            <h4 className="services-title">Dating App Development</h4>
                                            <p>Help people find their love. Create beautifully designed dating apps and
                                                websites with your innovative features to create convenient solutions for
                                                people to find partners to date. Our social dating application development
                                                solutions ensures the technology for your business idea.</p>

                                        </Col>
                                    </Row>
                                </TabPanel>

                            </Tabs>
                        </Col>
                    </Row>
                }
            </Container>
        </div>
    )

}

export default Services
