import React, { Fragment, } from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Button, Input } from 'reactstrap'
import Gif_img from "../../assets/img/404-annex.gif"

function PageNotFound() {
    return (
        <Fragment>
            <div className="contactus-bg-white">
                <div className="contactus-header" >
                    <div className="ani_right">
                        <svg xmlns="http://www.w3.org/2000/svg" link="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1024 1024">
                            <path id="followPath" d="M394.1-214.9c-49.7,89.4,114.4,192.8,175.5,475.1c13,60.1,85.4,424-98.1,552.6 c-95.7,67-267.2,74.5-346.3-22.1c-70.8-86.5-49-233.9,19.2-305.2c102.4-107,353.9-89.1,593.2,96.5c139.6,107,294.1,258.4,415,468.6 c19.2,33.5,36.6,66.6,52.3,99.3c13,8.6,34,19.5,53.3,13.2c148-48.6,165.1-1094.5-338.5-1374.8C723.7-320.8,449-313.8,394.1-214.9z"></path>
                            <path id="dashedPath" d="M394.1-214.9c-49.7,89.4,114.4,192.8,175.5,475.1c13,60.1,85.4,424-98.1,552.6 c-95.7,67-267.2,74.5-346.3-22.1c-70.8-86.5-49-233.9,19.2-305.2c102.4-107,353.9-89.1,593.2,96.5c139.6,107,294.1,258.4,415,468.6 c19.2,33.5,36.6,66.6,52.3,99.3c13,8.6,34,19.5,53.3,13.2c148-48.6,165.1-1094.5-338.5-1374.8C723.7-320.8,449-313.8,394.1-214.9z"></path>
                            <path id="airplain" d="M8.04 84L92 48 8.04 12 8 40l60 8-60 8z">
                                <animateMotion href="#airplain" dur="6s" fill="freeze" repeatCount="indefinite" rotate="auto">
                                    <mpath href="#followPath"></mpath>
                                </animateMotion>
                            </path>
                        </svg>
                    </div>
                    <div class="ani_inner_banner">
                        <div class="ani-cube ani-cube-3">
                            <div className="cube-face face_front" />
                            <div className="cube-face face_right" />
                            <div className="cube-face face_left" />
                            <div className="cube-face face_top" />
                            <div className="cube-face face_bottom" />
                            <div className="cube-face face_back" />
                        </div>
                    </div>
                    <div className="ani ani-5">
                        <div className="moving-square-frame" />
                        <div className="ani-moving-square" />
                    </div>

                    <Container>
                        <Row>
                            <Col md="12">
                                <h1 className="contactus-title">Page Not Found</h1>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item-active">Page Not Found</li>
                                </ol>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="pagenotfound">
                    <Container>
                        <Row>
                            <Col md="12">
                                <div className="img-gif text-center">
                                    <img src={Gif_img} alt="" />
                                </div>
                                <h2>Oops!, you are lost </h2>
                                <div className="homepage_btn">
                                    <Link to="/" className="form-submit-btn btn btn-secondary">Go To Home</Link>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                </div>
            </div>
        </Fragment>


    )
}

export default PageNotFound
