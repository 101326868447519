import React, { Component } from 'react'
import {
    Row,
    Col,
    Container
} from "reactstrap";
import WOW from 'wowjs';
import { Link } from 'react-router-dom'
import {SvgImg} from '../../comman/technologies_svg'



export class WeDo extends Component {

    constructor(props) {
        super(props);
        this.state = {
          showAnimation: false,
        };
        this.ref = React.createRef();
        this.imgRef  = React.createRef();
          }
          
          componentDidMount() {
            const wow = new WOW.WOW();
            wow.init();
            window.scrollTo({ top: 0,left: 0,behavior: 'auto'});
        }
    componentDidMount = () => {
        window.addEventListener("scroll", this.onScroll);
    };
        
    componentWillUnmount = () => {
        window.removeEventListener("scroll", this.onScroll);
    };
    
          
    onScroll = () => {
        const divHieght = this.imgRef.current.offsetTop
            this.setState({
                showAnimation: window.scrollY > this.ref.current.offsetTop - divHieght,
            });
        
    }
    
    render() {

        
         
        return (
            <div className="wedo-mainclass" ref={this.ref}>
            
                <Container>
                    <Row>
                        <Col lg="5" className="wedo-img">
                            <div className="round-animation">
                                <img src={require("assets/img/27.webp")} className="img-fluid round-animation-img" alt="" />

                                <div className="svg_upper" ref={this.imgRef}>
                                   {
                                       this.state.showAnimation ? <SvgImg /> : ''
                                   }
                                    
                               </div>
                            </div>
                        </Col>
                <Col lg="7" md="12" className="ml-auto md-mt-5">
                    <div className="wedo-text mb-4 wow slideInUp"  data-wow-delay="1">
                        <h4>What We Do</h4>
                        <h2>We Help Innovators <span> Implement Ideas </span></h2>
                        <p>AnnexLogics follows a dedicated engineering team model where <Link to="/about-us" className="custom_link">our team</Link> adapts to your DNA. We have a unique process while partnering with our clients.</p>
                        <ul className="list-unstyle my-3 d-inline-block wedo-title  wedo-list-icon">
                            <li>Share your product vision, roadmap and current product status</li>

                            <li>Build a dedicated team as per your requirements in terms of size, roles and
                                        technology experience</li>

                            <li>Set up the engineering process and tools, and grant access to these tools to
                                        the team</li>

                            <li>Conduct knowledge transfer sessions for the team in person or online</li>

                            <li>Induct the team into the regular development life cycle from planning
                                         sessions, to execution, to retrospectives</li>

                            <li>Conduct periodic engagement review meetings</li>
                        </ul>
                    </div>
                </Col>
                    </Row>
                </Container >
            </div >
        )
    }
}
export default WeDo;

