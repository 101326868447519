import React, { useState, useEffect } from "react";
import Popup from "reactjs-popup"
import { connect } from "react-redux"
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col
} from "reactstrap";
import { NavLink as RRNavLink, withRouter, Link } from 'react-router-dom';
import { getAddress } from "action";
let pathName = window.location.pathname
function ComponentsNavbar(props) {
  const [collapseOpen, setCollapseOpen] = useState(false)
  const [value, setValue] = useState(false)
  const [collapseOut, setCollapseOut] = useState("")
  const [color, setColor] = useState("navbar-transparent")
  const [pathname, setPathName] = useState(props.location.pathname)
  const [data, setData] = useState([])

  console.log(data);
  useEffect(() => {
    props.dispatchList()
    window.addEventListener("scroll", changeColor);
    return () => {
      window.removeEventListener("scroll", changeColor);
    }
  }, [])

  useEffect(() => {
    if (props.listStatus === "SUCCESS") {
      setData(props.data.data)
    }
  }, [props.listStatus, props.data])

  useEffect(() => {
    setPathName(props.location.pathname)
    window.addEventListener("scroll", changeColor);
    return () => {
      window.removeEventListener("scroll", changeColor);
    }

  }, [props.location.pathname])

  function changeColor() {

    if (
      document.documentElement.scrollTop > 99 ||
      document.body.scrollTop > 99
    ) {
      setColor("bg-info")


    } else if (
      document.documentElement.scrollTop < 100 ||
      document.body.scrollTop < 100
    ) {
      let pathValue = props.location.pathname;



      setColor("navbar-transparent")
    }
  };
  function toggleCollapse() {
    document.documentElement.classList.toggle("nav-open");

    setCollapseOpen(!collapseOpen)
  };
  function onCollapseExiting() {

    setCollapseOut("collapsing-out")
  };
  function onCollapseExited() {

    setCollapseOut("")
  };
  function changeSide() {
    setValue(!value)
  }

  return (
    <Navbar
      className={"fixed-top " + color}
      color-on-scroll="100"
      expand="lg"
    >
      <Container>
        <div className="navbar-translate">
          <NavbarBrand
            to="/"
            tag={Link}
            id="navbar-brand"
            href="/"
          >

            <img className="annex-logo" src={require("assets/img/annex-logo-white.png")} alt="" />
          </NavbarBrand>
          <div>
            <button
              aria-expanded={collapseOpen}
              className="navbar-toggler navbar-toggler"
              onClick={toggleCollapse}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
            <button className="button-pop table-view" onClick={changeSide}>
              <span className="ht-nav-toggle"><span></span> </span>
            </button>

          </div>
        </div>
        <Collapse
          className={"justify-content-center " + collapseOut}
          navbar
          isOpen={collapseOpen}
          onExiting={onCollapseExiting}
          onExited={onCollapseExited}
        >

          <Nav navbar>
            <NavItem className={pathname === "/technologies" || pathname.includes('details') || pathname === "/services" || pathname === "/technologies" || pathname === "/about-us" || pathname === "/contact-us" ? "p-0 " : "p-0 nav-bar-active"}>

              <NavLink
                tag={RRNavLink}
                exact to="/"
                onClick={() => setCollapseOpen(false)}

              >
                Home
              </NavLink>

            </NavItem>
            <NavItem className={pathname === "/services" ? "p-0 nav-bar-active" : "p-0"}>
              <NavLink
                tag={RRNavLink}
                exact to="/services"
                onClick={() => setCollapseOpen(false)}

              >
                Services
              </NavLink>
            </NavItem>
            <NavItem className={pathname === "/technologies" || pathname.includes('details') ? "p-0 nav-bar-active" : "p-0"}>
              <NavLink
                tag={RRNavLink}
                exact to="/technologies"
                onClick={() => setCollapseOpen(false)}

              >
                Technologies
              </NavLink>
            </NavItem>

            <NavItem className={pathname === "/about-us" ? "p-0 nav-bar-active" : "p-0"}>
              <NavLink
                tag={RRNavLink}
                exact to="/about-us"
                onClick={() => setCollapseOpen(false)}

              >
                About Us
              </NavLink>
            </NavItem>

            <NavItem className="p-0">


              <a href="https://annexlogics.com/blog" className="nav-link">Blog</a>
            </NavItem>

            <NavItem className={pathname === "/contact-us" ? "p-0 nav-bar-active" : "p-0"}>
              <NavLink
                tag={RRNavLink}
                exact to="/contact-us"
                onClick={() => setCollapseOpen(false)}

              >
                Hire Us
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
        <div>




          <div className={value ? "showside " : ""}>
            <button onClick={changeSide} className="button-pop distop-view"><span className={` ht-nav-toggle`}><span></span> </span></button>
            <div className="nav-popup">
              <span className="close" onClick={changeSide}>
                <span></span>
              </span>
              <Container>
                <Row>

                  {/* <button onClick={changeSide}>Demo</button> */}
                  <Col md="12">
                    <img src={require("assets/img/logo.png")} className="img-fluid side-logo mb-3" alt="" />
                    <p className="mb-5  side-text">AnnexLogics - Engineering Services, IT Consultants and Business Services. We are a leading company
                      in mobile app development, website design and development, salesforce, digital marketing, and various other online business services.</p>
                    <div className="form-info">
                      <h4 className="title">Contact info</h4>

                      {data.map((val, index) => {
                        if (index == 0) {

                          return (

                            <ul className="contact-info list-unstyled mt-4">
                              <li className="mb-4 site-contactus-icon">
                                <i className="flaticon-location"></i>
                                <span>Address</span>
                                <p>{val.address}</p>
                              </li>
                              <li className="mb-4 site-contactus-icon">
                                <i className="flaticon-phone-ringing"></i>
                                <span>PHONE:</span>
                                <a href={"tel:" + val?.mobile[0]?.phone}>{val?.mobile[0]?.phone}</a>
                              </li>
                              <li className="mb-4 site-contactus-icon">
                                <i className="flaticon-email"></i>
                                <span>EMAIL</span>
                                <a href={"mailto:" + val.email}> {val.email}</a>
                              </li>
                            </ul>
                          )
                        }
                      })}


                    </div>
                    <div className="mt-5 site-social-icon">
                      <ul className="list-inline">
                        <li className="mb-2">

                          <a href="https://www.facebook.com/AnnexLogics/" className="social-icons-fackbook"><i className="fab fa-facebook-f"></i></a>
                        </li>
                        <li className="mb-2">
                          <a href="https://twitter.com/annexlogics" className="social-icons-twitter"><i className="fab fa-twitter"></i></a>
                        </li>
                        <li className="mb-2">
                          <a href="https://www.linkedin.com/company/annexlogics-system-pvt-ltd/" className="social-icons-linkedin"><i className="fab fa-linkedin-in"></i></a>
                        </li>
                        <li className="mb-2">
                          <a href="skype:live:info_298476" className="social-icons-skype"><i className="fab fa-skype"></i></a>
                        </li>
                        <li className="mb-2">
                          <a href="https://www.instagram.com/annexlogics/" className="social-icons-skype"><i className="fab fa-instagram"></i></a>
                        </li>
                      </ul>
                    </div>

                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
      </Container>
    </Navbar >
  );
}

const mapStateToProps = state => ({
  data: state.addressList.message,
  listStatus: state.addressList.status,
})

const mapDispatchToProps = dispatch => ({
  dispatchList: data => dispatch(getAddress(data)),
})

//export default withRouter(connect(mapStateToProps, mapDispatchToProps), (ComponentsNavbar));
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ComponentsNavbar));

//export default withRouter(ComponentsNavbar);
