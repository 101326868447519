import React, { useEffect, useState } from 'react'
import { Container, Row, Col, } from 'reactstrap'
import WOW from 'wowjs';
import { Helmet } from "react-helmet";
import { getCase } from "../../action/index"
import { connect } from "react-redux"
import {LargeLoader} from "../../comman/loader"
import { Android, Flutter, Reactico, Ios, Ionic, Magento, WooCommerce, Shopify, Opencart, BigCommerce, LMS, CRM, ERP, SIS, RubyOnRails, Laravel, Salesforce, Codeigniter, Symphony } from '../../comman/technologies_svg'
function TechnologiesPage(props) {
    const [data, setData] = useState([])
    const [loading,setLoading] = useState(true)  
    useEffect(() => {                
        const wow = new WOW.WOW();
        wow.init();
        window.scrollTo({ top: 0,left: 0,behavior: 'auto'});
    props.dispatchList()
}, [])
    useEffect(() => {
        if (props.listStatus === "SUCCESS") {
            setData(props.data.data)
            setLoading(false)
        }
    }, [props.listStatus, props.data])

    

    
    return (
        <div className="technologiespage-bg-white">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Technologies | Annexlogics</title>
                <meta name="description" content="AnnexLogics has professional and dedicated team to design and develop your online business. Our team always believe in innovation and deliver quality work on time. " />

            </Helmet>
            <div className="technologiespage-header" >
            <div className="ani_right">
              <svg xmlns="http://www.w3.org/2000/svg" link="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1024 1024">
                        <path id="followPath" d="M394.1-214.9c-49.7,89.4,114.4,192.8,175.5,475.1c13,60.1,85.4,424-98.1,552.6 c-95.7,67-267.2,74.5-346.3-22.1c-70.8-86.5-49-233.9,19.2-305.2c102.4-107,353.9-89.1,593.2,96.5c139.6,107,294.1,258.4,415,468.6 c19.2,33.5,36.6,66.6,52.3,99.3c13,8.6,34,19.5,53.3,13.2c148-48.6,165.1-1094.5-338.5-1374.8C723.7-320.8,449-313.8,394.1-214.9z"></path>
                        <path id="dashedPath" d="M394.1-214.9c-49.7,89.4,114.4,192.8,175.5,475.1c13,60.1,85.4,424-98.1,552.6 c-95.7,67-267.2,74.5-346.3-22.1c-70.8-86.5-49-233.9,19.2-305.2c102.4-107,353.9-89.1,593.2,96.5c139.6,107,294.1,258.4,415,468.6 c19.2,33.5,36.6,66.6,52.3,99.3c13,8.6,34,19.5,53.3,13.2c148-48.6,165.1-1094.5-338.5-1374.8C723.7-320.8,449-313.8,394.1-214.9z"></path>
                        <path id="airplain" d="M8.04 84L92 48 8.04 12 8 40l60 8-60 8z">
                            <animateMotion href="#airplain" dur="6s" fill="freeze" repeatCount="indefinite" rotate="auto">
                                <mpath href="#followPath"></mpath>
                            </animateMotion>
                        </path>
                    </svg>
                 </div>
                 <div class="ani_inner_banner"> 
                  <div class="ani-cube ani-cube-3">
                        <div className="cube-face face_front" />
                        <div className="cube-face face_right" />
                        <div className="cube-face face_left" />
                        <div className="cube-face face_top" />
                        <div className="cube-face face_bottom" />
                        <div className="cube-face face_back" />
                    </div>
                  </div>
                  <div className="ani ani-5">
                  <div className="moving-square-frame" />
                  <div className="ani-moving-square" />
                  </div>
                <Container>
                    <Row>

                        <Col md="12">
                            <h1 className="technologiespage-title">Technologies</h1>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                <li className="breadcrumb-item-active">Technologies</li>
                            </ol>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="technologiespage-content">
                <Container>
                <Row className="text-center mt-5">
                        <Col lg="8" md="12" className="ml-auto mr-auto">
                            <div className="technologiespage-title-section">                           
                                <h2>Our  <span>Technologies</span></h2>
                            </div>
                        </Col>
                    </Row>

                 {/* ===================Mobile App development services================= */}
                    <div className="mobile_app mt-5">
                    <Row>
                        <Col lg="5" md="12">
                        <div className="technologies-title">                           
                                <h2>Mobile App Development Services</h2>
                                <p>We, as a mobile application development company can provide high-quality standard mobile apps of any category. We have expertise in developing mobile app development solutions to meet your business requirements.</p>
                                </div>
                        </Col>
                        <Col lg="7" md="12" >
                        <Row>
                        <Col lg="4" md="12" >
                        <div className="servicepage-section1-card wow fadeInLeft" data-wow-delay="0.6"> 
                               <div className="technologies-icons bg-and">                                 
                                    <Android />
                                </div>
                                <h5>Android</h5>                                    
                            </div>
                            </Col>
                            <Col lg="4" md="12" >
                            <div className="servicepage-section1-card wow fadeInUp" data-wow-delay="0.6">                                  
                            <div className="technologies-icons bg-flutt">                                 
                                    <Flutter />
                                </div>
                                <h5>Flutter</h5>                                    
                            </div>
                            </Col>
                            <Col lg="4" md="12" >
                            <div className="servicepage-section1-card wow fadeInRight" data-wow-delay="0.6">                                  
                            <div className="technologies-icons bg-react">                                 
                                    <Reactico />
                                </div>
                                <h5>React</h5>                                    
                            </div>
                            </Col>
                            <Col lg="2" md="12" className="mt-3"></Col>
                            <Col lg="4" md="12" className="mt-3">
                            <div className="servicepage-section1-card wow fadeInLeft" data-wow-delay="0.6"> 
                               <div className="technologies-icons bg-ios">                                 
                                    <Ios />
                                </div>
                                <h5>Ios</h5>                                    
                            </div>
                            </Col>
                            <Col lg="4" md="12" className="mt-3">
                            <div className="servicepage-section1-card wow fadeInRight" data-wow-delay="0.6">                                  
                            <div className="technologies-icons bg-ionic">                                 
                                    <Ionic />
                                </div>
                                <h5>Ionic</h5>                                    
                            </div>
                            </Col>
                            <Col lg="2" md="12" className="mt-3"></Col>

                            


                            </Row>
                        </Col>
                                             
                    </Row>
                        
                    </div>

                 {/* ================================================================ */}

                    {/* ===================E-Commerce Development Services================= */}

                    <div className="mobile_app ecommerce_bg mt-5">
                    <Row>                       
                        <Col lg="7" md="12" >
                        <Row>
                        <Col lg="4" md="12" >
                        <div className="servicepage-section1-card wow fadeInLeft" data-wow-delay="0.6"> 
                               <div className="technologies-icons bg-magento">                                 
                                    <Magento />
                                </div>
                                <h5>Magento</h5>                                    
                            </div>
                            </Col>
                            <Col lg="4" md="12" >
                            <div className="servicepage-section1-card wow fadeInUp" data-wow-delay="0.6">                                  
                            <div className="technologies-icons bg-woocomerce">                                 
                                    <WooCommerce />
                                </div>
                                <h5>WooCommerce</h5>                                    
                            </div>
                            </Col>
                            <Col lg="4" md="12" >
                            <div className="servicepage-section1-card wow fadeInRight" data-wow-delay="0.6">                                  
                            <div className="technologies-icons bg-shopify">                                 
                                    <Shopify />
                                </div>
                                <h5>Shopify</h5>                                    
                            </div>
                            </Col>
                            <Col lg="2" md="12" className="mt-3"></Col>
                            <Col lg="4" md="12" className="mt-3">
                            <div className="servicepage-section1-card wow fadeInLeft" data-wow-delay="0.6"> 
                               <div className="technologies-icons bg-opencart">                                 
                                    <Opencart />
                                </div>
                                <h5>Opencart</h5>                                    
                            </div>
                            </Col>
                            <Col lg="4" md="12" className="mt-3">
                            <div className="servicepage-section1-card wow fadeInRight" data-wow-delay="0.6">                                  
                            <div className="technologies-icons bg-bigcommerce">                                 
                                    <BigCommerce />
                                </div>
                                <h5>BigCommerce</h5>                                    
                            </div>
                            </Col>
                            <Col lg="2" md="12" className="mt-3"></Col>
                            </Row>
                        </Col>
                        <Col lg="5" md="12">
                        <div className="technologies-title">                           
                                <h2>E-Commerce Development Services</h2>
                                <p>Are you ready to start your own Internet business? Everything You Need To Start Selling Online Today. Secure Shopping Cart. Easy Maintenance. Cost Reduction Integrated Sales Channel.</p>
                                </div>
                        </Col>
                                             
                    </Row>
                        
                    </div>

                      {/* ================================================================ */}

                    {/* ===================PHP Development Services================= */}
                    <div className="mobile_app mt-5">
                    <Row>
                        <Col lg="5" md="12">
                        <div className="technologies-title">                           
                                <h2>PHP Development <br/> Services</h2>
                                <p>Providing the best integration systems having PHP framework in the best way possible. Our PHP experts will help you with your requirements regarding these softwares.</p>
                                </div>
                        </Col>
                        <Col lg="7" md="12" >
                        <Row>
                        <Col lg="4" md="12" >
                        <div className="servicepage-section1-card wow fadeInLeft" data-wow-delay="0.6"> 
                               <div className="technologies-icons bg-lms">                                 
                                    <LMS />
                                </div>
                                <h5>LMS</h5>                                    
                            </div>
                            </Col>
                            <Col lg="4" md="12" >
                            <div className="servicepage-section1-card wow fadeInUp" data-wow-delay="0.6">                                  
                            <div className="technologies-icons bg-crm">                                 
                                    <CRM />
                                </div>
                                <h5>CRM</h5>                                    
                            </div>
                            </Col>
                            <Col lg="4" md="12" >
                            <div className="servicepage-section1-card wow fadeInRight" data-wow-delay="0.6">                                  
                            <div className="technologies-icons bg-erp">                                 
                                    <ERP />
                                </div>
                                <h5>ERP</h5>                                    
                            </div>
                            </Col>
                            <Col lg="2" md="12" className="mt-3"></Col>
                            <Col lg="4" md="12" className="mt-3">
                            <div className="servicepage-section1-card wow fadeInLeft" data-wow-delay="0.6"> 
                               <div className="technologies-icons bg-opencart">                                 
                                    <Opencart />
                                </div>
                                <h5>Opencart</h5>                                    
                            </div>
                            </Col>
                            <Col lg="4" md="12" className="mt-3">
                            <div className="servicepage-section1-card wow fadeInRight" data-wow-delay="0.6">                                  
                            <div className="technologies-icons bg-sis">                                 
                                    <SIS />
                                </div>
                                <h5>SIS</h5>                                    
                            </div>
                            </Col>
                            <Col lg="2" md="12" className="mt-3"></Col>


                            </Row>
                        </Col>
                                             
                    </Row>
                        
                    </div>
                     {/* ================================================================ */}

                      {/* ===================Custom Development Services================= */}
                       
                      <div className="mobile_app ecommerce_bg mt-5">
                    <Row>                       
                        <Col lg="7" md="12" >
                        <Row>
                        <Col lg="4" md="12" >
                        <div className="servicepage-section1-card wow fadeInLeft" data-wow-delay="0.6"> 
                               <div className="technologies-icons bg-rubyrails">                                 
                                    <RubyOnRails />
                                </div>
                                <h5>Ruby On Rails</h5>                                    
                            </div>
                            </Col>
                            <Col lg="4" md="12" >
                            <div className="servicepage-section1-card wow fadeInUp" data-wow-delay="0.6">                                  
                            <div className="technologies-icons bg-laravel">                                 
                                    <Laravel />
                                </div>
                                <h5>Laravel</h5>                                    
                            </div>
                            </Col>
                            <Col lg="4" md="12" >
                            <div className="servicepage-section1-card wow fadeInRight" data-wow-delay="0.6">                                  
                            <div className="technologies-icons bg-salesforce">                                 
                                    <Salesforce />
                                </div>
                                <h5>Salesforce</h5>                                    
                            </div>
                            </Col>
                            <Col lg="2" md="12" className="mt-3"></Col>
                            <Col lg="4" md="12" className="mt-3">
                            <div className="servicepage-section1-card wow fadeInLeft" data-wow-delay="0.6"> 
                               <div className="technologies-icons bg-cordi">                                 
                                    <Codeigniter />
                                </div>
                                <h5>Codeigniter</h5>                                    
                            </div>
                            </Col>
                            <Col lg="4" md="12" className="mt-3">
                            <div className="servicepage-section1-card wow fadeInRight" data-wow-delay="0.6">                                  
                            <div className="technologies-icons bg-symphony">                                 
                                    <Symphony />
                                </div>
                                <h5>Symphony</h5>                                    
                            </div>
                            </Col>
                            <Col lg="2" md="12" className="mt-3"></Col>
                            </Row>
                        </Col>
                        <Col lg="5" md="12">
                        <div className="technologies-title">                           
                                <h2>Custom Development <br/> Services</h2>
                                <p>Custom development is what we are best in. Our organization specializes in the field of cutom development in many languages like Angular, Php open edX, php bbpress, Pyhthon, Django, Devops etc...</p>
                                </div>
                        </Col>
                                             
                    </Row>
                        
                    </div>

                       {/* ================================================================ */}
                   
                </Container>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    listStatus: state.caseList.status,
    data: state.caseList.message,
})

const mapDispatchToProps = dispatch => ({
    dispatchList: data => dispatch(getCase(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TechnologiesPage)
