import { ADDRESS_LIST, ADD_LEAD, TEAM_LIST, CASE_STUDY, CASE_STUDY_DETAIL, CLIENT_LIST, TESTIMONIAL_LIST, SERVICES_LIST, ADD_SUBSCRIBER } from "./ActionType"

export const getAddress = () => {

    return {
        type: ADDRESS_LIST
    }
}
export const addLead = (payload) => {
    return {
        type: ADD_LEAD,
        payload
    }
}
export const getTeam = () => {
    return {
        type: TEAM_LIST
    }
}
export const getCase = () => {
    return {
        type: CASE_STUDY
    }
}

export const caseStudyDetail = (payload) => {
    return {
        type: CASE_STUDY_DETAIL,
        payload
    }
}

export const clientList = () => {
    return {
        type: CLIENT_LIST
    }
}
export const TestimonialList = () => {
    return {
        type: TESTIMONIAL_LIST
    }
}
export const servicesList = () => {
    return {
        type: SERVICES_LIST
    }
}

export const subscribe = (payload) => {
    return {
        type: ADD_SUBSCRIBER,
        payload
    }
}