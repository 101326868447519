import React, { Component } from 'react'
import {
    Row,
    Col
} from "reactstrap";
import WOW from 'wowjs';

export class Technologes extends Component {
    componentDidMount() {
        const wow = new WOW.WOW();
        wow.init();
        window.scrollTo({ top: 0,left: 0,behavior: 'auto'});
    }
    
    render() {
        return (
            <div className="technologes-main">            
                <span className="iq-objects-03 topBottom"><span className="iq-round"></span></span>                
                 <div className="technologes-bg ">
                        <img src={require("assets/img/03.webp")} className="img-fluid img-rotateme" alt="" />
                    </div>
                    <div className="container-fluid">
                        <Row className="align-items-center">
                            <Col lg="6" md="12" className="order-lg-12">
                                <img   src={require("assets/img/technologes.webp")} className="img-fluid topBottom" alt="" />
                            </Col>
                            <Col lg="6" md="12" className="order-lg-1 md-mt-5">
                                <Row>
                                    <Col lg="6" md="6">
                                        <div className="technologes-type wow flipInY " data-wow-duration="1s">
                                        <img src={require("assets/img/svg/technologes/big-data.svg")} alt=""/>
                                            <h5>Big Data</h5>
                                            <p>Hadoop</p>
                                        </div>
                                    </Col>
                                    <Col lg="6" md="6" className="mt-5">
                                    <div className="technologes-type">
                                        <img src={require("assets/img/svg/technologes/ux-design.svg")} alt=""/>
                                            <h5>Frontend</h5>
                                            <p>Reactjs , Angularjs</p>
                                        </div>
                                    </Col>
                                    <Col lg="6" md="6" className="sm-mt-5">
                                    <div className="technologes-type">
                                        <img src={require("assets/img/svg/technologes/cloud-service.svg")} alt=""/>                                           
                                            <h5>Cloud</h5>
                                            <p>Salesforce, AWS, Azure, Google Cloud</p>
                                        </div>
                                    </Col>
                                    <Col lg="6" md="6" className="mt-5">
                                    <div className="technologes-type wow flipInY " data-wow-duration="1s">
                                        <img src={require("assets/img/svg/technologes/backend.svg")} alt=""/>
                                            <h5>Backend</h5>
                                            <p>Ruby on Rails, Python, Laravel, Symfony, CakePHP, Nodejs, Java, .Net</p>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
            </div>
        )
    }
}

export default Technologes ;
