import React, { Component } from 'react'
import { Container, Row, Col, Button } from "reactstrap";
import WOW from 'wowjs';
import {withRouter,Link} from "react-router-dom"
export class IndexHeader extends Component {
    componentDidMount() {
        const wow = new WOW.WOW();
        wow.init();
        window.scrollTo({ top: 0,left: 0,behavior: 'auto'});
    }

    navigateContactus(){
        // this.props.history.push('/contact_us')
       
    }
    render() {
        return (
            <div className="Index-header-bg">
              <div className="ani_right">
              <svg xmlns="http://www.w3.org/2000/svg" link="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1024 1024">
                        <path id="followPath" d="M394.1-214.9c-49.7,89.4,114.4,192.8,175.5,475.1c13,60.1,85.4,424-98.1,552.6 c-95.7,67-267.2,74.5-346.3-22.1c-70.8-86.5-49-233.9,19.2-305.2c102.4-107,353.9-89.1,593.2,96.5c139.6,107,294.1,258.4,415,468.6 c19.2,33.5,36.6,66.6,52.3,99.3c13,8.6,34,19.5,53.3,13.2c148-48.6,165.1-1094.5-338.5-1374.8C723.7-320.8,449-313.8,394.1-214.9z"></path>
                        <path id="dashedPath" d="M394.1-214.9c-49.7,89.4,114.4,192.8,175.5,475.1c13,60.1,85.4,424-98.1,552.6 c-95.7,67-267.2,74.5-346.3-22.1c-70.8-86.5-49-233.9,19.2-305.2c102.4-107,353.9-89.1,593.2,96.5c139.6,107,294.1,258.4,415,468.6 c19.2,33.5,36.6,66.6,52.3,99.3c13,8.6,34,19.5,53.3,13.2c148-48.6,165.1-1094.5-338.5-1374.8C723.7-320.8,449-313.8,394.1-214.9z"></path>
                        <path id="airplain" d="M8.04 84L92 48 8.04 12 8 40l60 8-60 8z">
                            <animateMotion href="#airplain" dur="6s" fill="freeze" repeatCount="indefinite" rotate="auto">
                                <mpath href="#followPath"></mpath>
                            </animateMotion>
                        </path>
                    </svg>
                    </div>
                <img src={require("assets/img/08.webp")} className="img-fluid indexheader-bg-img" alt="" />
                <div className="Page-container ">
                    <Container>
                  
                        <Row className ="heading-row">
                            <Col lg="6" md="12" sm="12" className="sm-mt-5 order-lg-12 text-center ">
                                <img src={require("assets/img/banner_img.webp")} className=" img-fluid"  alt=""/>
                            </Col>
                            <Col lg="6" md="12" sm="12" className="order-lg-1 md-mt-5 " >
                                <h1 className="page-heading md-4 animated animate__fadeInUp delay-2 duration-2">Robust And Scalable Mobile & Web  App <span> Development Services </span> </h1>
                                <p className="heading-detail animated animate__fadeInUp delay-3 duration-3">Annexlogics is a globally identified mobile and web application development company with boundless mobility solutions and esteemed on-demand apps.</p>
                                <div className="">
                                    <Link to="/contact-us" className="heading-us">
                                    {/* <Button className="heading-btn" onClick={this.navigateContactus} ><span>Get in touch</span></Button> */}
                                <Button className="aboutus-title-button animated animate__fadeInUp delay-3 duration-3" onClick={this.navigateContactus} ><span>Get in touch</span></Button>
                                </Link>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        )
    }
}

export default withRouter(IndexHeader);
