import { LargeLoader } from 'comman/loader'
import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from "reactstrap"
function Team(props) {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState(props.data)

    useEffect(() => {
        setLoading(props.loading)
        setData(props.data)
    }, [props])

    return (

        
        <div className="creative-team">

            <Container>  
                {loading ? <Row>
                    <LargeLoader style="mt-4" />
                </Row> :

        <div id="testimonial-2" className="testimonial-carousel carousel slide testimonial-2" >         

          <div className="row">                                 
            <div className="col-md-8 mx-auto">
              <div className="carousel-inner">
                                             
                <div className="carousel-item active">
                  <div className="testimonial style-2">
                  {data.map((val, index) => {
                    if(index < 1){
                      return ( 
                               
                               <React.Fragment> <div className="testimonial-img">
                                 <img src={val.image} className="img-fluid zoom-fade" alt="" />                               
                                </div>
                                <div className="names">{val.position}</div>
                                </React.Fragment>
                                            
                            )
                    }
                           
                        })}     
                    <div className="testimonial-content">
                      <div className="testimonial-quote"><i className="la la-quote-left"></i>
                      </div>
                      <div className="testimonial-caption">
                        <h5>Creative Team</h5>                       
                      </div>
                      <h3>Meet Our Expert team member will ready for your service</h3>
                      
                    </div>
                  </div>
                </div>
                    
              </div>
            </div>
            
          </div>
          <div className="controls">
            <ul className="nav">
            
            {data.map((val, index) => { 
                    if(index > 0){
                      return ( 
                        <React.Fragment> 
                        <li className="active">
                        <div className="item_img">
                <a href="#">
                <img src={val.image}  className="img-fluid zoom-fade"  alt=""/>
                </a> 
                </div>
                <span className="names">{val.position}</span>
              </li>
              </React.Fragment>
              
                            )
                    }
                           
                        })}   
           
              
            </ul>
          </div>

        

        </div>  
      }              
            </Container>
        </div>
    )
}


export default Team
