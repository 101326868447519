import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from "reactstrap"
import OwlCarousel from 'react-owl-carousel';

export const ClientLogo = (props) => {

    const [data, setData] = useState([])
    const responsive = {
        0: {
            items: 1,
        },
        768: {
            items: 2,
        },
        1000: {
            items: 1,
        },
    }
    // console.log(' Line 19 ', props.data);

    useEffect(() => {
        // console.log(' Line 20 ', props.data);
        setData(props.data)
    }, [props.data,])

    let finalRender = <span>Loading...</span>
    if (data.length > 0) {
        finalRender = <OwlCarousel loop={true} autoplay={true} lazyLoad={true} mouseDrag={true} nav={false} dots={false} dotData={true} responsive={responsive} center={false} smartSpeed={'200'} slidetransition={'linear'} autoplaySpeed={'1000'} margin={0} >
            {data.map((val, index) => {

                return (

                    <Row className="" key={index}>
                        {val.map((logo, index) => {
                            return (
                                <Col lg="6" md="6" xs="6" key={index}  >
                                    <div className="clients-logo" >
                                        <img className="owl-lazy" data-src={logo} data-src-retina={logo} alt="" />
                                    </div>
                                </Col>
                            )

                        })}

                    </Row>


                )
            })}
        </OwlCarousel>
    }

    return (

        <div>
            <Row className="" className={`client-div aboutusnews-client ${props.style}`}>
                <Col lg="5" md="12" className="ml-auto mr-auto">
                    <div className="News-title Home-customer-title">
                        <h2 className="section-title">Our  <span>Customers</span></h2>

                    </div>
                </Col>

                <Col lg="7" md="12">
                    <span className="iq-objects-03"><img src={require("assets/img/testi_m.png")} className="img-fluid topBottom" alt="" /></span>
                    {finalRender}
                </Col>

            </Row>

        </div>

    )
}


export default ClientLogo
