import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap'
export default class Count extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customer_counter: 0,
            project_done: 0,
            review_counter: 0,
            retention_counter: 0
        }
    }
    componentDidMount() {
        window.scrollTo({ top: 0,left: 0,behavior: 'auto'});
        this.interval = setInterval(() => this.customer(), 200);
        this.interval = setInterval(() => this.retention(), 453);
        this.interval = setInterval(() => this.project(), 190);
        this.interval = setInterval(() => this.review(), 453);
    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }
    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.loading == this.props.loading
    }
    customer() {
        if (this.state.customer_counter < 230) {
            this.setState(prevState => ({
                customer_counter: prevState.customer_counter + 1
            }));
        }
    }
    review() {
        if (this.state.review_counter < 100) {
            this.setState(prevState => ({
                review_counter: prevState.review_counter + 1
            }));
        }
    }
    project() {
        if (this.state.project_done < 250) {
            this.setState(prevState => ({
                project_done: prevState.project_done + 1
            }));
        }
    }
    retention() {
        if (this.state.retention_counter < 99) {
            this.setState(prevState => ({
                retention_counter: prevState.retention_counter + 1
            }));
        }
    }
    render() {
        return (
            <div className="aboutuspage-counter pt-0">
                <Container>
                    <Row>
                        <Col lg="3" md="3" sm="6">
                            <div className="counter-style">
                            <img src={require("assets/img/svg/home_about/projects-completed.svg")} alt=""/>
                                <span>{this.state.project_done}</span>
                                <h5>Projects Done</h5>
                            </div>
                        </Col>
                        <Col lg="3" md="3" sm="6" className="xs-mt-5">
                            <div className="counter-style">
                            <img src={require("assets/img/svg/home_about/retention-rate.svg")} alt=""/>
                                <span>{this.state.retention_counter}</span>
                                <h5>Retention Rate</h5>
                            </div>
                        </Col>
                        <Col lg="3" md="3" sm="6" className="sm-mt-5">
                            <div className="counter-style">
                            <img src={require("assets/img/svg/home_about/reviews.svg")} alt=""/>
                                <span>{this.state.review_counter}</span>
                                <h5>Reviews</h5>
                            </div>
                        </Col>
                        <Col lg="3" md="3" sm="6" className="sm-mt-5">
                            <div className="counter-style">
                            <img src={require("assets/img/svg/home_about/customer.svg")} alt=""/>
                                <span>{this.state.customer_counter}</span>
                                <h5>Customers</h5>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
