import React, { Component } from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Row } from "reactstrap"
import { LargeLoader } from 'comman/loader';
import { withRouter } from 'react-router';
export class Aboutuspage_Slider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            responsive: {
                0: {
                    items: 1,
                },
                768: {
                    items: 2,
                },
                1000: {
                    items: 4,
                },



            },
            data: [],
            loading: true
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        return true


    }
    componentDidUpdate(nextProps) {
        if (nextProps.loading !== this.props.loading) {
            this.setState({ data: this.props.data, loading: this.props.loading })
        }
    }

    GoToDetails(val) {
        if (val.url) {
            var win = window.open(val.url, '_blank');
            win.focus();
        } else {


            this.props.history.push(`/details/${val.slug}`)
        }
    }
    render() {
        return (
            <div className="aboutus-slider">
                {this.state.loading ? <Row >
                    <LargeLoader style="mt-5" />
                </Row> :
                    <OwlCarousel loop={true} mouseDrag={true} nav={true} navText={["<span class='fas fa-long-arrow-alt-left'></span>", "<span class='fas fa-long-arrow-alt-right'></span>"]} className="owl-aboutus-theme " responsive={this.state.responsive} center={true} autoplayTimeout={'2000'} items={4} data-md-items={2}>
                        {this.state.data.map((val, index) => {
                            return (
                                <div className="owl-aboutus" key={index}>
                                    <div className="owl-aboutus-items">
                                        <div className="owl-aboutus-image">
                                            <img src={val.photo} className="img-fluid" alt="" />
                                        </div>
                                        <div className="owl-aboutus-description">

                                            <h5 onClick={() => this.GoToDetails(val)}>{val.title}</h5>
                                            <span>{val.subtitle}</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}

                    </OwlCarousel>
                }

            </div>
        )
    }
}

export default withRouter(Aboutuspage_Slider)
