import React from 'react'
import { Row, Col } from "reactstrap"
export default function Video() {
    return (
        <div className="video-main">  
        <div className="round-p-animation wow flipInY" data-wow-duration="1s"/>    
                <div className="ani ani-5">
                    <div className="moving-square-frame"></div>
                    <div className="ani-moving-square"></div>
                </div>      
            <div className="container-fluid">
                <Row>
                    <Col lg="6" md="10" className="ml-auto mr-auto">
                        <div className="video-title">
                            <h2>Our Working <span> Process </span></h2>
                            
                        </div>
                    </Col>
                </Row>
               
            </div>
            <div className="video-main-img">  
                        <video className="video-player" autoPlay={true} muted={true} loop >
                            <source src={require("assets/video/home.mp4")} type="video/mp4" />
                        </video>
                  </div>
        </div>
    )
}
