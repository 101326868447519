import React, { Fragment, useEffect } from 'react'
import PageHeader from "../../comman/PageHeader"
import { Container, Row, Col } from 'reactstrap'

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { Helmet } from "react-helmet";
import {Link} from "react-router-dom";


function FAQ() {
    useEffect(() => {
        window.scrollTo({ top: 0,left: 0,behavior: 'auto'});
    }, [])
    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Faq | AnnexLogics</title>
                <meta name="description" content="AnnexLogics System Pvt. Ltd. established globally as a leading software company and partner with both start-ups & established businesses globally." />

            </Helmet>
            <PageHeader title="FAQ" />
            <div className="faq-content">
                <Container >
                    <Row>
                        <Col lg="12" md="12">
                            <h2 className="faq-title">FAQ</h2>
                        </Col>
                    </Row>
                    <Row className="mt-3" >
                        <Col lg="12" md="12">

                            <Accordion className="main-accordian">
                                <AccordionSummary  expandIcon={
                                <ExpandMoreIcon />
                                } aria-controls="panel1a-content">
                                <h4 className="faq-text mt-5">1) Should I create a mobile or a web app?</h4>
                                </AccordionSummary>
                                <AccordionDetails className="faq-subtext">
                                    Both have their benefits and flaws. Mobile apps are surely more expensive but can provide you with many more data collecting, monetisation capabilities than web applications. Progressive Web Apps are a good compromise between a mobile and web app — you can learn more about PWA on our blog. But remember that sooner or later, you may need both mobile and web app, and when that moment comes, we are here for you :)
                                </AccordionDetails>
                            </Accordion>
                            <Accordion className="main-accordian">
                                <AccordionSummary  expandIcon={
                                <ExpandMoreIcon />
                                } aria-controls="panel1a-content" className="faq-text mt-5">
                                <h4 className="faq-text mt-5">2) What do I need to know before contacting you?</h4>
                                </AccordionSummary>
                                <AccordionDetails className="faq-subtext">
                                    Well, the most important thing to know is what do you want to accomplish. Why do I need this software? What for? What should it do? Having a clear vision in mind is crucial when ordering a software application. You don't want to spend many months developing it with us without being sure what you need. If you have no experience in app development, feel free to read our blog entry on that topic. We'll also be glad to help you get started; building projects from scratch is nothing new for us.
                                </AccordionDetails>
                            </Accordion>
                            <Accordion className="main-accordian">
                                <AccordionSummary  expandIcon={
                                <ExpandMoreIcon />
                                } aria-controls="panel1a-content" className="faq-text mt-5">
                                    <h4 className="faq-text mt-5">3) How much time will it take for you to make my app?</h4>
                                </AccordionSummary>
                                <AccordionDetails className="faq-subtext">To give you a precise answer, we must prepare a project scope and create a budget. We shall tell you how much of that scope can be completed within the budget, and we'll be able to estimate how long will it take after at least a month of work. Don't worry, and if you're not happy with our work after one week, you can retract from the project with no penalties. This is our trial period and we believe that any reliable software house should give you one.</AccordionDetails>
                            </Accordion>
                            <Accordion className="main-accordian">
                                <AccordionSummary  expandIcon={
                                <ExpandMoreIcon />
                                } aria-controls="panel1a-content" className="faq-text mt-5">
                                    <h4 className="faq-text mt-5">4) How do you guarantee product quality?</h4>
                                </AccordionSummary>
                                <AccordionDetails className="faq-subtext">We evaluate the result after every two weeks, and we test our work (we conduct both development and acceptance tests), present it to you, and apply your feedback, so you know you get what you are paying for.</AccordionDetails>
                            </Accordion>
                            <Accordion className="main-accordian">
                                <AccordionSummary  expandIcon={
                                <ExpandMoreIcon />
                                } aria-controls="panel1a-content" className="faq-text mt-5">
                                    <h4 className="faq-text mt-5">5) I want to be involved in the app development process; do you have a problem with that?</h4></AccordionSummary>
                                <AccordionDetails className="faq-subtext">Not at all! We want you to be sure you'll receive the product you desire. From the planning stage to each finished version, we invite you to evaluate and improve our work. Our philosophy is to work with the client, not merely finish given tasks. We are always ready to listen and communicate.</AccordionDetails>
                            </Accordion>
                            <Accordion className="main-accordian">
                                <AccordionSummary  expandIcon={
                                <ExpandMoreIcon />
                                } aria-controls="panel1a-content" className="faq-text mt-5">
                                    <h4 className="faq-text mt-5">6) Why should I choose you and not hire my own software development team?</h4></AccordionSummary>
                                <AccordionDetails className="faq-subtext">Hiring your own software developers can be a really smart choice; however, the recruitment process is time-consuming, and the costs of keeping an in-house programming team are very high. You'll probably need to employ your own software engineers sooner or later, but hiring us can help you save a lot of money and time (which is basically the same in a business project).</AccordionDetails>
                            </Accordion>
                            <Accordion className="main-accordian">
                                <AccordionSummary  expandIcon={
                                <ExpandMoreIcon />
                                } aria-controls="panel1a-content" className="faq-text mt-5"><h4 className="faq-text mt-5">7) What happens after you finish my app?</h4></AccordionSummary>
                                <AccordionDetails className="faq-subtext">Hopefully, the app is exactly what you dreamed of :) But apart from delivering a finished product, we provide you with free technical support and app maintenance should you need it for the first two months. After all, we know our work inside out. Of course, if you want to maintain the app by yourself, the source code and all technical data are at your disposal, but even in that case, feel free to contact us if you need any help.</AccordionDetails>
                            </Accordion>
                            <Accordion className="main-accordian">
                                <AccordionSummary  expandIcon={
                                <ExpandMoreIcon />
                                } aria-controls="panel1a-content" className="faq-text mt-5"><h4 className="faq-text mt-5">8) Can the software be upgraded in the future as new technology becomes available?</h4></AccordionSummary>
                                <AccordionDetails className="faq-subtext">Of course! We are always happy to improve, upgrade and further develop our work.</AccordionDetails>
                            </Accordion>
                            <Accordion className="main-accordian">
                                <AccordionSummary  expandIcon={
                                <ExpandMoreIcon />
                                } aria-controls="panel1a-content" className="faq-text mt-5"><h4 className="faq-text mt-5">9) How much do you charge?</h4></AccordionSummary>
                                <AccordionDetails className="faq-subtext">It depends on many things: project scope, time spent on the project and technological issues. We know it sounds vague, but it's simply honest. Remember that each software project is different, especially if you had a particularly original idea. We do not want to give you an unrealistic estimate; instead, we want to provide you with the best quality possible within your budget.</AccordionDetails>
                            </Accordion>
                            <Accordion className="main-accordian">
                                <AccordionSummary  expandIcon={
                                <ExpandMoreIcon />
                                } aria-controls="panel1a-content" className="faq-text mt-5"><h4 className="faq-text mt-5">10) What technology do you use?</h4></AccordionSummary>
                                <AccordionDetails className="faq-subtext">For back-end we use Laravel, ROR, php & Symfony and Node.js. For front-end we use React.js & HTML5, Laravel, Wordpress & HTML. For mobile we use React Native, Flutter & Native.</AccordionDetails>
                            </Accordion>                            
                            <Accordion className="main-accordian">
                                <AccordionSummary  expandIcon={
                                <ExpandMoreIcon />
                                } aria-controls="panel1a-content" className="faq-text mt-5"><h4 className="faq-text mt-5">11) Do you provide any services apart from Software Development?</h4></AccordionSummary>
                                <AccordionDetails>
                                <Typography className="faq-subtext">
                                    Yes, we can also help you with Product Design and Digital Transformation Strategy.<br></br><br></br>
                                <b>Product Design</b> <br></br>We focus on understanding your idea during the product design phase that how it can be effectively realised. We begin with workshops that help us find out what are your needs and expectations. We also prepare market and competition analysis as well as point you towards the best solutions. All this allows us to create prototypes that will collect feedback from potential users and perfect our mock-ups throughout the entire process. <br></br><br></br> <b>Digital Transformation</b> <br></br>Digital transformation is an organisational change of approach, which leads to more agile and data-driven decision-making and optimising and digitising core business processes. The purpose is to work better and more effectively while providing more value to customers at the same time. To help you achieve this, we combine our tech, product development and business skills. Our customers receive market analysis regarding the latest technological trends occurring in their line of business, as well as innovative digital product conceptualisations and prototypes that will help validate those concepts and evaluate risk. If you have any questions that we haven’t covered here, or if you would like to discuss how we can help your organisation, <Link  to="/contact-us" > contact us</Link>!
                                </Typography>
                            </AccordionDetails>
                            </Accordion>

                        </Col>
                        </Row>
                </Container>
            </div>
        </Fragment>
    )
}

export default FAQ
