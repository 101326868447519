import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Input } from 'reactstrap'
import { Formik, Form } from "formik"
import { Helmet } from "react-helmet";
import { connect } from "react-redux"
import { getAddress, addLead } from "../../action/index"
import { CircleLoader, LargeLoader } from "../../comman/loader"
import { Link } from "react-router-dom"
import Gif_img from "../../assets/img/404-annex.gif"
const digitsOnly = /^\d+$/
const codeRegex = /^[a-zA-Z ]+$/;
let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
function HirUs(props) {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [load, setLoad] = useState(true)
    const [message, setMessage] = useState('')
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
        props.dispatchList()
        setLoading(false)
    }, [])
    useEffect(() => {
        if (props.listStatus === "SUCCESS") {
            setData(props.data.data)
            setLoad(false)
        }
    }, [props.listStatus, props.data])
    useEffect(() => {
        if (props.formStatus === "SUCCESS") {
            setMessage("Request successfully submited")
        } else if (props.formStatus === "FAILED") {
            setMessage(props.formMessage)

        }
    }, [props.formStatus])
    return (
        <div className="contactus-bg-white">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Contact Us | Hire Us | AnnexLogics</title>
                <meta name="description" content="AnnexLogics is the best team of experienced and dedicated professionals to develop your online business. Contact us at info@annexlogics.com" />

            </Helmet>
            <div className="contactus-header" >
                <div className="ani_right">
                    <svg xmlns="http://www.w3.org/2000/svg" link="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1024 1024">
                        <path id="followPath" d="M394.1-214.9c-49.7,89.4,114.4,192.8,175.5,475.1c13,60.1,85.4,424-98.1,552.6 c-95.7,67-267.2,74.5-346.3-22.1c-70.8-86.5-49-233.9,19.2-305.2c102.4-107,353.9-89.1,593.2,96.5c139.6,107,294.1,258.4,415,468.6 c19.2,33.5,36.6,66.6,52.3,99.3c13,8.6,34,19.5,53.3,13.2c148-48.6,165.1-1094.5-338.5-1374.8C723.7-320.8,449-313.8,394.1-214.9z"></path>
                        <path id="dashedPath" d="M394.1-214.9c-49.7,89.4,114.4,192.8,175.5,475.1c13,60.1,85.4,424-98.1,552.6 c-95.7,67-267.2,74.5-346.3-22.1c-70.8-86.5-49-233.9,19.2-305.2c102.4-107,353.9-89.1,593.2,96.5c139.6,107,294.1,258.4,415,468.6 c19.2,33.5,36.6,66.6,52.3,99.3c13,8.6,34,19.5,53.3,13.2c148-48.6,165.1-1094.5-338.5-1374.8C723.7-320.8,449-313.8,394.1-214.9z"></path>
                        <path id="airplain" d="M8.04 84L92 48 8.04 12 8 40l60 8-60 8z">
                            <animateMotion href="#airplain" dur="6s" fill="freeze" repeatCount="indefinite" rotate="auto">
                                <mpath href="#followPath"></mpath>
                            </animateMotion>
                        </path>
                    </svg>
                </div>
                <div class="ani_inner_banner">
                    <div class="ani-cube ani-cube-3">
                        <div className="cube-face face_front" />
                        <div className="cube-face face_right" />
                        <div className="cube-face face_left" />
                        <div className="cube-face face_top" />
                        <div className="cube-face face_bottom" />
                        <div className="cube-face face_back" />
                    </div>
                </div>
                <div className="ani ani-5">
                    <div className="moving-square-frame" />
                    <div className="ani-moving-square" />
                </div>
                <Container>
                    <Row>
                        <Col md="12">
                            <h1 className="contactus-title">Contact Us</h1>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                <li className="breadcrumb-item-active">Contact Us</li>
                            </ol>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="contactus-content">
                <Container>
                    <Row>

                        <Col lg="6" md="12" className="conactus-item-left pl-0">

                            {load ? <LargeLoader /> :

                                <Row>
                                    <h5>Get In Touch</h5>
                                    {data.map((val, index) => {
                                        if (val.country === "For work inquiry" || val.country === "For career inquiry") {
                                            return (
                                                <Col lg="6" md="12" className="conactus-card">
                                                    <div className="contactus-office">
                                                        <h3>{val.country}</h3>
                                                        <ul className="contactus-info-list">
                                                            <li className="mb-3"><a href={`mailto:${val.email}`}>{val.email}</a></li>
                                                            {(val.country === "For work inquiry") ?
                                                                <li className="mb-3"><a href="mailto:business@annexlogics.com">business@annexlogics.com</a></li>
                                                                :
                                                                ""
                                                            }
                                                            {val.mobile.map((values, idx) => {
                                                                return (
                                                                    <li className="mb-3" key={idx}><a href={`tel:${values.phone}`}>{values.phone}</a></li>
                                                                )
                                                            })}

                                                        </ul>
                                                    </div>
                                                </Col>
                                            )
                                        }

                                    })}

                                </Row>
                            }



                            {load ? <LargeLoader /> :

                                <Row>
                                    <h5 className="mt-5">Global Presence</h5>
                                    {data.map((val, index) => {
                                        if (index < 2) {

                                            return (
                                                <Col lg="6" md="12" key={index} className="conactus-card">
                                                    <div className={val.country.toLowerCase() === "australia" ? "australia-office contactus-office" : "contactus-office"}>
                                                        <h3>{val.country}</h3>
                                                        <ul className="contactus-info-list">
                                                            <li className="mb-3">
                                                                <p>{val.address}</p>
                                                            </li>
                                                            {val.mobile.map((values, idx) => {
                                                                return (
                                                                    <li className="mb-3" key={idx}><a href={`tel:${values.phone}`}>{values.phone}</a></li>
                                                                )
                                                            })}

                                                            <li className="mb-3"><a href={`mailto:${val.email}`}>{val.email}</a></li>
                                                        </ul>
                                                    </div>
                                                </Col>
                                            )
                                        }
                                    })}

                                </Row>
                            }
                        </Col>
                        {loading ?
                            <Col lg="6" md="12" className="order-lg-12 pl-0 pr-0"><div className="contact-section">
                                <div className="thanks-img"> <img src={require("assets/img/cmpted_logo.jpg")} className="img-fluid" alt="" /></div>
                                <div className="form-group mt-5">
                                    <Link className="form-submit-btn back-home" type="submit" to="/" >Back To Home</Link>

                                </div>
                            </div> </Col>
                            :
                            <Col lg="6" md="12" className="order-lg-12 pl-0 pr-0 order_xs">
                                <div className="contact-section">
                                    <div className=" contact-section-title">
                                        <h6>Get In Touch</h6>
                                    </div>
                                    <Formik
                                        initialValues={{ name: '', email: '', phone: '', message: '' }}
                                        onSubmit={(values, actions) => {
                                            setLoading(true)
                                            props.dispatchLead(values)
                                            actions.resetForm({ values: { name: '', email: '', phone: '', message: '' } })
                                        }}
                                        validate={(values) => {
                                            let errors = {};
                                            if (values.name === "") {
                                                errors.name = "Name is required.";
                                            }
                                            else if (!codeRegex.test(values.name)) {
                                                errors.name = "Enter only alphabets";
                                            }
                                            if (values.email === "") {
                                                errors.email = "Valid email is required."
                                            } else if (!regEmail.test(values.email)) {
                                                errors.email = "Valid email is required."
                                            }
                                            if (!digitsOnly.test(values.phone)) {
                                                errors.phone = "Valid phone is required."
                                            }
                                            if (values.phone.length < 10) {
                                                errors.phone = "Valid phone is required."
                                            }
                                            if (values.phone === "") {
                                                errors.phone = "Phone is required."
                                            }

                                            if (values.message === "") {
                                                errors.message = "Please,leave us a message."
                                            }
                                            return errors;
                                        }}
                                    >
                                        {({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            handleSubmit,
                                            setFieldTouched
                                        }) => (
                                            <Form onSubmit={handleSubmit} >
                                                <Row>
                                                    <Col md="6" className="form-group">
                                                        <Input type="text" className="form-control" placeholder="Name" value={values.name} onBlur={() => setFieldTouched('name')}
                                                            onChange={(text) => handleChange(text)} name="name" />
                                                        {errors['name'] && touched['name'] ?
                                                            <span className="hirus-errors">{errors['name']}</span> :
                                                            null
                                                        }
                                                    </Col>
                                                    <Col md="6" className="form-group">
                                                        <Input type="text" className="form-control" placeholder="Email" name="email" value={values.email} onBlur={() => setFieldTouched('email')}
                                                            onChange={(text) => handleChange(text)} />
                                                        {errors['email'] && touched['email'] ?
                                                            <span className="hirus-errors">{errors['email']}</span>
                                                            : null}
                                                    </Col>
                                                    <Col md="12" className="form-group">
                                                        <Input type="text" className="form-control" placeholder="Phone" maxLength={'10'} name="phone" value={values.phone} onBlur={() => setFieldTouched('phone')}
                                                            onChange={(text) => handleChange(text)} />
                                                        {errors['phone'] && touched['phone'] ?
                                                            <span className="hirus-errors">{errors['phone']}</span> : null
                                                        }
                                                    </Col>
                                                    <Col md="12" className="form-group">
                                                        <textarea className="form-control" rows="4" placeholder="Message" name="message" value={values.message} onBlur={() => setFieldTouched('message')}
                                                            onChange={(text) => handleChange(text)} />
                                                        {errors['message'] && touched['message'] ?
                                                            <span className="hirus-errors">{errors['message']}</span> : null
                                                        }
                                                    </Col>
                                                    <Col md="12" className="form-group mt-5">
                                                        <Button className="form-submit-btn" type="submit" onChange={handleSubmit}>{loading ? <CircleLoader /> : "Send Message"}</Button>
                                                    </Col>
                                                    <p className="success_msg">{message}</p>
                                                </Row>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </Col>
                        }
                    </Row>
                    <Row>
                        <Col lg="12" md="12" className="conactus-item-left pl-0">
                            {load ? <LargeLoader /> :
                                <Row>

                                    {data.map((val, index) => {
                                        if (index > 1) {

                                            if (val.country !== "For work inquiry" && val.country !== "For career inquiry") {
                                                return (
                                                    <Col lg="3" md="12" key={index} className="conactus-card">
                                                        <div className={val.country.toLowerCase() === "australia" ? "australia-office contactus-office" : "contactus-office"}>
                                                            <h3>{val.country}</h3>
                                                            <ul className="contactus-info-list">
                                                                <li className="mb-3">
                                                                    <p>{val.address}</p>
                                                                </li>
                                                                {val.mobile.map((values, idx) => {
                                                                    return (
                                                                        <li className="mb-3" key={idx}><a href={`tel:${values.phone}`}>{values.phone}</a></li>
                                                                    )
                                                                })}

                                                                <li className="mb-3"><a href={`mailto:${val.email}`}>{val.email}</a></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                )
                                            }

                                        }
                                    })}

                                </Row>
                            }
                        </Col>
                    </Row>
                </Container>
            </div>

        </div>
    )
}

const mapStateToProps = state => ({
    listStatus: state.addressList.status,
    data: state.addressList.message,
    formStatus: state.lead.status,
    formMessage: state.lead.message
})

const mapDispatchToProps = dispatch => ({
    dispatchList: data => dispatch(getAddress(data)),
    dispatchLead: data => dispatch(addLead(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(HirUs)
