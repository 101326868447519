import React from "react";
export const CircleLoader = () => {
    return (
        <div className="small-loader">
        <div class="loader">
            <svg viewBox="0 0 80 80">
                <circle id="test" cx="40" cy="40" r="32"></circle>
            </svg>
        </div>
        </div>
    )
}
export const LargeLoader = (props) =>{
    const {style} = props
    return(
        <div className={`large-loader ${style}`}>
            <div className="box1" />
            <div className="box2" />
            <div className="box3" />
        </div>
    )
}