import { LargeLoader } from 'comman/loader';
import React, { useEffect, useState } from 'react'
import {
    Row,
    Col,
    Carousel,
    CarouselItem,
    CarouselIndicators,
    Container
} from "reactstrap";

function Aboutus_Testimonial(props) {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
       
        if (!props.loading) {
            setLoading(props.loading)
            setData(props.data)
        }
    }, [props.loading, props.data])

    const onExiting = () => {
        setAnimating(true);
    };
    const onExited = () => {
        setAnimating(false);
    };
    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === data.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };
    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? data.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };
    const goToIndex = newIndex => {
        if (animating) return;
        setActiveIndex(newIndex);
    };


    return (
        <div className="aboutus-testimonial">
            <Container>
                <Row className="text-center">
                    <Col lg="8" md="12" className="mr-auto ml-auto">
                        <div className="aboutus-testimonial-title">
                            <h6>Testimonial</h6>
                            <h2>You Can See our clients feedback What <span> You Say? </span></h2>
                        </div>
                    </Col>
                </Row>
                {loading ? <Row>
                    <LargeLoader style="mt-4" />
                </Row>:
                <Row >
                    <Col md="12" lg="12">
                        <Carousel
                            interval="5000"
                            activeIndex={activeIndex}
                            next={next}
                            previous={previous}
                            ride="carousel"
                        >
                            <CarouselIndicators
                                items={data}
                                activeIndex={activeIndex}
                                onClickHandler={goToIndex}
                            />
                            {data.map(item => {
                                return (
                                    <CarouselItem

                                        onExiting={onExiting}
                                        onExited={onExited}
                                        key={item.id}
                                    >
                                        <Row className="align-items-center">
                                            <Col lg="6" md="12">
                                                <div className="aboutus-testimonial-img">
                                                <img src={require("assets/img/abuts_test.png")} className="img-fluid "  alt=""/>  
                                                </div>
                                            </Col>
                                            <Col lg="5" md="12" className="ml-auto mr-auto md-mt-5">
                                                <div className="aboutus-testimonial-text">
                                                    <div className="testimonial-quote">
                                                        <i className="flaticon-quotation" ></i>
                                                    </div>
                                                    <p>{item.description}</p>
                                                    <div className="testimonial-caption">
                                                        <h5>{item.name}</h5>
                                                        <label> {item.position} </label>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                    </CarouselItem>
                                );
                            })}

                        </Carousel>

                    </Col>
                </Row>
}
            </Container>
        </div>
    )
}

export default Aboutus_Testimonial
