import React, { useEffect } from 'react'
import { Container, Row, Col } from 'reactstrap'
import PageHeader from "../../comman/PageHeader"
import { Helmet } from "react-helmet";

function TAC() {
    useEffect(() => {
        window.scrollTo({ top: 0,left: 0,behavior: 'auto'});
    }, [])
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>AnnexLogics Terms and Conditions</title>
                <meta name="description" content="Know More about the Terms and Conditions on access to AnnexLogics and materials thereon." />

            </Helmet>
            <PageHeader title="Terms And Conditions" />
            <div className="terms-content">
                <Container >
                    <Row>
                        <Col lg="12" md="12">
                            <h2 className="terms-title">Terms and <span>Conditions</span></h2>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col lg="12" md="12">
                            <p className="terms-subtext">These terms and conditions outline the rules and regulations for the use of AnnexLogics System Pvt. Ltd.'s Website, located at https://www.annexlogics.com.</p>
                            <p className="terms-subtext">By accessing this website we assume you accept these terms and conditions. Do not continue to use AnnexLogics if you do not agree to take all of the terms and conditions stated on this page.</p>
                            <p className="terms-subtext">The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: "Client", "You" and "Your" refers to you, the person log on this website and compliant to the Company’s terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client’s needs in respect of provision of the Company’s stated services, in accordance with and subject to, prevailing law of Netherlands. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same.</p>
                            <h4 className="terms-text ">Cookies</h4>
                            <p className="terms-subtext mb-3">We employ the use of cookies. By accessing AnnexLogics, you agreed to use cookies in agreement with the AnnexLogics System Pvt. Ltd.'s Privacy Policy.</p>
                            <p className="terms-subtext">Most interactive websites use cookies to let us retrieve the user’s details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.</p>
                            <h4 className="terms-text mt-5">License</h4>
                            <p className="terms-subtext mb-3">Unless otherwise stated, AnnexLogics System Pvt. Ltd. and/or its licensors own the intellectual property rights for all material on AnnexLogics. All intellectual property rights are reserved. You may access this from AnnexLogics for your own personal use subjected to restrictions set in these terms and conditions.</p>
                            <p className="terms-subtext mb-3">You must not:</p>
                            <ul className="list-unstyled list-icon mb-3">
                                <li className="mb-3 terms-list">
                                    <i className="flaticon-double-check"></i>
                                    Republish material from AnnexLogics
                                </li>
                                <li className="mb-3 terms-list">
                                    <i className="flaticon-double-check"></i>
                                    Sell, rent or sub-license material from AnnexLogics
                                </li>
                                <li className="mb-3 terms-list">
                                    <i className="flaticon-double-check"></i>
                                    Reproduce, duplicate or copy material from AnnexLogics
                                </li>
                                <li className="mb-3 terms-list">
                                    <i className="flaticon-double-check"></i>
                                    Redistribute content from AnnexLogics
                                </li>
                            </ul>
                            <p className="terms-subtext mb-5">This Agreement shall begin on the date hereof.</p>
                            <p className="terms-subtext ">Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas of the website. AnnexLogics System Pvt. Ltd. does not filter, edit, publish or review Comments prior to their presence on the website. Comments do not reflect the views and opinions of AnnexLogics System Pvt. Ltd.,its agents and/or affiliates. Comments reflect the views and opinions of the person who post their views and opinions. To the extent permitted by applicable laws, AnnexLogics System Pvt. Ltd. shall not be liable for the Comments or for any liability, damages or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.</p>
                            <p className="terms-subtext ">AnnexLogics System Pvt. Ltd. reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive or causes breach of these Terms and Conditions.</p>

                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default TAC
