import React, { useEffect, useState } from "react";
// reactstrap components
import {
  Form,
  Row,
  Col,
  Input,
  Button,
  Container
} from "reactstrap";
import { Link } from "react-router-dom"
import { getAddress, subscribe } from "../../action/index"
import { connect, useSelector } from 'react-redux'
import { CircleLoader } from "../../comman/loader"
function Footer(props) {

  //const data = useSelector(props => props.data)

  const [data, setData] = useState([])
  const [values, setValues] = useState("")
  const [errors, setErrors] = useState("")
  const [loading, setLoading] = useState(false)


  useEffect(() => {
    if (props.listStatus === "SUCCESS") {
      setData(props.data.data)
    }
  }, [props.listStatus, props.data])

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
    props.dispatchList()

  }, [])

  function validate() {
    let formIsValid = true;
    let errors = {};
    let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(values)) {
      formIsValid = false
      errors["values"] = "Valid email is required.";
    }
    if (!values) {
      formIsValid = false
      errors["values"] = "Valid email is required.";
    }
    setErrors(errors)
    return formIsValid;
  }
  function handleClick(e) {
    const err = validate()
    e.preventDefault()

    if (err) {
      setLoading(true)
      props.dispatchAction(values)
    }
  }
  useEffect(() => {
    if (props.status === "SUCCESS") {
      setLoading(false)
    }
    if (props.status === "FAILED") {
      setLoading(false)
      alert(JSON.stringify(props.message))
    }
  }, [props.status])
  return (
    <footer className="footer">

      <div className="container-fluid p-0 footer-content">

        <Row >
          <Col lg="4" >
            <div className="footer-intro">
              <img className="footer-logo" src={require("assets/img/annex-logo-purp.png")} alt="" />
              <p className="mb-3 footer-intro-text">We have extensive experience in developing enterprise level IT solutions for wide range of
                domains. The core feature of our company is that we focus on client’s satisfaction, ensuring that our each step
                goes towards success and meet our client’s expectation. We value customer happiness!</p>
              <a href="/#" className="footer-readmore">Read More <i className="fas fa-long-arrow-alt-right"></i></a>
              <div className="mt-5">
                <h4 className="footer-follow">Follow Us</h4>
                <ul className="list-inline">
                  <li className="social-link">
                    <a href="https://www.facebook.com/AnnexLogics/" target="_blank">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li className="social-link">
                    <a href="https://twitter.com/annexlogics" target="_blank">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li className="social-link">
                    <a href="https://www.linkedin.com/company/annexlogics-system-pvt-ltd" target="_blank">
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                  <li className="social-link">
                    <a href="skype:live:info_298476" target="_blank">
                      <i className="fab fa-skype"></i>
                    </a>
                  </li>

                  <li className="social-link">
                    <a href="https://www.instagram.com/annexlogics/" target="_blank"><i className="fab fa-instagram"></i></a>
                  </li>

                </ul>
              </div>
            </div>
          </Col>

          <Col lg="8" className="footer-section-2 ">
            <Row>
              <Col lg="6" md="6">
                <h4>Useful Link</h4>
                <Row>
                  <Col md="6" sm="7">
                    <ul className="footer-link">
                      <li><Link to="/about-us">About Us</Link></li>
                      <li><Link to="/services">Our Service</Link></li>
                      <li><Link to="/technologies">Technologies</Link></li>
                      {/* <li> <Link to="#">Blog</Link></li> */}
                      <li><Link to="/faq">Faq</Link></li>
                    </ul>
                  </Col>
                  <Col md="6" sm="7">
                    <ul className="footer-link">
                      <li><Link to="/about-us">Our Team</Link></li>
                      <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                      <li><Link to="/terms-and-conditions">Terms & Condition</Link></li>
                      <li><Link to="/contact-us">Contact Us </Link></li>
                    </ul>
                  </Col>
                </Row>
              </Col>
              <Col lg="6" md="6" className="sm-mt-5">
                <h4>Contact</h4>

                {data?.map((val, index) => {
                  if (index == 0) {
                    return (
                      <ul>
                        <li key={index}>{val?.address}</li>
                        <li><a href={"tel:" + val?.mobile[0]?.phone}>{val?.mobile[0]?.phone}</a></li>
                        <li><a href="mailto:sales@annexlogics.com">sales@annexlogics.com</a></li>
                        <li><a href={"mailto:" + val?.email}> {val?.email}</a></li>
                      </ul>
                    )
                  }
                })}
                {/* <li>175, Ashram Marg, infront of Gold Gym,
                    Nemi Nagar,<br /> Vaishali Nagar, Jaipur, Rajasthan 302021</li> */}



              </Col>
            </Row>
            <Row className="mt-5">
              <Col xl="10" lg="12" md="12" className="ml-auto" >
                <div className="footer-news px-3 py-3 d-md-flex align-items-center">
                  <h3 className="mb-0" >NewsLetter</h3>
                  <div className="subscribe-form-style sm-mt-2">
                    <Form className="subscribe-form" onSubmit={handleClick}>
                      <Input onChange={(e) => setValues(e.target.value)} type="email" name="email" placeholder="Email Address" required={true} />
                      <Button type="submit" className="subscribe-btn" onClick={handleClick}>{loading ? <CircleLoader /> : "Subscribe"}</Button>
                    </Form>
                  </div>
                  <span className="footer-errors">{errors['values']}</span>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="secondary-footer">
        <Container>

          <Row>
            <Col md="6"><span>COPYRIGHT 2021 | ALL RIGHTS RESERVED</span>
            </Col>
            <Col md="6" className="text-md-right sm-mt-2"><span>DESIGNED AND DEVELOPED BY <a href="/" className="copyright-annex">AnnexLogics</a>
            </span></Col>
          </Row>

        </Container>
      </div>
    </footer>
  );
}


const mapStateToProps = state => ({
  listStatus: state.addressList.status,
  data: state.addressList.message,
  status: state.subscribe.status,
  message: state.subscribe.message,

})

const mapDispatchToProps = dispatch => ({
  dispatchAction: data => dispatch(subscribe(data)),
  dispatchList: data => dispatch(getAddress(data))

})

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
