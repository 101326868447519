import { all, } from "redux-saga/effects";
import addressList from "./AddressList";
import lead from "./lead";
import caseList from "./CaseList";
import clientList from "./ClientList";
import teamList from "./teamList";
import testimonialList from "./TestimonialList";
import servicesList from "./ServicesList";
import subscribe from "./Subscribe";
import casedetail from "./CaseDetail";

export default function* rootSaga() {
    yield all([
        addressList(),
        lead(),
        caseList(),
        clientList(),
        teamList(),
        testimonialList(),
        servicesList(),
        subscribe(),
        casedetail()
    ])
    // code after all-effect
}