import { createStore, compose, applyMiddleware } from "redux"
import rootReducer from "../reducer/rootReducer"
import rootSaga from "saga/rootSaga";
import createSagaMiddleware from 'redux-saga'


const sagaMiddleware = createSagaMiddleware();

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    rootReducer,
    // storeEnhancers(applyMiddleware(sagaMiddleware))
    applyMiddleware(sagaMiddleware)
)
sagaMiddleware.run(rootSaga)

export default store;