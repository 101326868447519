function lead(state = { status: null, message: "" }, action) {
    switch (action.type) {
        case "ADD_LEAD":
            return {
                status: null,
                message:""
            }
        case "LEAD_SUCCESS":
            return {
                ...state,
                status: "SUCCESS",
                message: action.resultData
            }
        case "LEAD_FAILED":
            return {
                ...state,
                status: "FAILED",
                message: action.resultData
            };

        default:
            return state;
    }
}

export default lead;