import React, { Component } from 'react'
import "../../assets/fonts/flaticon.css"
import { Container, Row, Col, } from 'reactstrap'
import WOW from 'wowjs';
import { Helmet } from "react-helmet";
export class ServicesPage extends Component {
    componentDidMount() {
        const wow = new WOW.WOW();
        wow.init();
        window.scrollTo({ top: 0,left: 0,behavior: 'auto'});
    }
    render() {
        return (
            <div className="servicepage-bg-white">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>IT Support Services | Mobile & Web Development Services</title>
                    <meta name="description" content="Our experience in developing mobile & web applications, cloud, game development, and digital marketing can help you use the latest tools to build and grow your business."/>

                </Helmet>
                <div className="service-page-header">

                <div className="ani_right">
                 <svg xmlns="http://www.w3.org/2000/svg" link="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1024 1024">
                        <path id="followPath" d="M394.1-214.9c-49.7,89.4,114.4,192.8,175.5,475.1c13,60.1,85.4,424-98.1,552.6 c-95.7,67-267.2,74.5-346.3-22.1c-70.8-86.5-49-233.9,19.2-305.2c102.4-107,353.9-89.1,593.2,96.5c139.6,107,294.1,258.4,415,468.6 c19.2,33.5,36.6,66.6,52.3,99.3c13,8.6,34,19.5,53.3,13.2c148-48.6,165.1-1094.5-338.5-1374.8C723.7-320.8,449-313.8,394.1-214.9z"></path>
                        <path id="dashedPath" d="M394.1-214.9c-49.7,89.4,114.4,192.8,175.5,475.1c13,60.1,85.4,424-98.1,552.6 c-95.7,67-267.2,74.5-346.3-22.1c-70.8-86.5-49-233.9,19.2-305.2c102.4-107,353.9-89.1,593.2,96.5c139.6,107,294.1,258.4,415,468.6 c19.2,33.5,36.6,66.6,52.3,99.3c13,8.6,34,19.5,53.3,13.2c148-48.6,165.1-1094.5-338.5-1374.8C723.7-320.8,449-313.8,394.1-214.9z"></path>
                        <path id="airplain" d="M8.04 84L92 48 8.04 12 8 40l60 8-60 8z">
                            <animateMotion href="#airplain" dur="6s" fill="freeze" repeatCount="indefinite" rotate="auto">
                                <mpath href="#followPath"></mpath>
                            </animateMotion>
                        </path>
                    </svg>
                 </div>
                 <div class="ani_inner_banner"> 
                  <div class="ani-cube ani-cube-3">
                        <div className="cube-face face_front" />
                        <div className="cube-face face_right" />
                        <div className="cube-face face_left" />
                        <div className="cube-face face_top" />
                        <div className="cube-face face_bottom" />
                        <div className="cube-face face_back" />
                    </div>
                  </div>
                  <div className="ani ani-5">
                  <div className="moving-square-frame" />
                  <div className="ani-moving-square" />
                  </div>


                    <Container>
                        <Row>
                            <Col md="12">
                                <h1 className="servicepage-title">Services</h1>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item-active">Services</li>
                                </ol>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="servcepage-section-1">
                <div className="about-bg">
                        <img src={require("assets/img/03.webp")} className="img-fluid img-rotateme" alt=""/>
                    </div>
                    <Container>
                        <Row>
                        <Col lg="6" md="12">
                        <div className="fancy_service  wow fadeInUp  text-left" >
								<div className="bg_img">
									<img src={require("assets/img/testi_m.png")} alt="fancybox" className="topBottom" />
								</div>
								<div className="fancy_block">
									<div className="fancy-info">
										<h4 className="mt-4 mb-3 ">Cloud Application Development</h4>
										<p className=""> 
                                         <ul>
                                         <li>Amazon Web Services</li>    
                                         <li>Microsoft Azure</li>
                                         <li>Infrastructure as a Service (IaaS)</li>  
                                         <li>Platform as a Service (PaaS)</li>  
                                         <li>Software as a Service (SaaS)</li>                                                                                                                              
                                         </ul>
                                         </p>
										
										<a href="#">
											<span className="btn-effect blue-color">
												Read More
												<i className="fa fa-plus-circle ml-2" aria-hidden="true"></i>
											</span>
										</a>
									</div>
									<div className="fancy_img"><img src={require("assets/img/svg/services_svg/application.svg")} alt=""/></div>
								</div>
							</div>
                            </Col>
                            <Col lg="6" md="12">
                             <div className="fancy_service  wow fadeInUp  text-left" >
								<div className="bg_img">
									<img  src={require("assets/img/testi_m.png")} alt="fancybox"  className="topBottom" />
								</div>
								<div className="fancy_block">
									<div className="fancy-info">
										<h4 className="mt-4 mb-3 ">Application Development</h4>
										<p className=""> 
                                         <ul>
                                         <li>CRM Applications</li>    
                                         <li>Microsoft Azure</li>
                                         <li>Financial Accounting</li>  
                                         <li>Hospital Management System</li>  
                                         <li>HR Applications</li>                                                                                                                              
                                         <li>Inventory Management</li>                                                                                                                              
                                         <li>School Management System</li>                                                                                                                              
                                         </ul>
                                         </p>
										<a href="#">
											<span className="btn-effect blue-color">
												Read More
												<i className="fa fa-plus-circle ml-2" aria-hidden="true"></i>
											</span>
										</a>
									</div>
									<div className="fancy_img"><img src={require("assets/img/svg/services_svg/application-development.svg")} alt=""/></div>
								</div>
							</div>
                            </Col>

                             <Col lg="6" md="12">
                             <div className="fancy_service  wow fadeInUp  text-left" >
								<div className="bg_img">
									<img  src={require("assets/img/testi_m.png")} alt="fancybox"  className="topBottom" />
								</div>
								<div className="fancy_block">
									<div className="fancy-info">
										<h4 className="mt-4 mb-3 ">Game Development Studio</h4>
										<p className=""> 
                                         <ul>
                                         <li>IOS Game Development</li>    
                                         <li>ANDROID Game Development</li>
                                         <li>WINDOWS Game Development</li>  
                                         <li>AR Game Development</li>  
                                         <li> VR Game Development</li>                                                                                                                              
                                         </ul>
                                         </p>
										<a href="#">
											<span className="btn-effect blue-color">
												Read More
												<i className="fa fa-plus-circle ml-2" aria-hidden="true"></i>
											</span>
										</a>
									</div>
									<div className="fancy_img">
                                    <img src={require("assets/img/svg/services_svg/game-development.svg")} alt=""/>
                                    </div>
								</div>
							</div>
                            </Col>

                            <Col lg="6" md="12">
                             <div className="fancy_service  wow fadeInUp  text-left" >
								<div className="bg_img">
									<img  src={require("assets/img/testi_m.png")} alt="fancybox"  className="topBottom" />
								</div>
								<div className="fancy_block">
									<div className="fancy-info">
										<h4 className="mt-4 mb-3 ">Independent Quality Control</h4>
										<p className=""> 
                                         <ul>
                                         <li>Compatibility Testing</li>    
                                         <li>Functional Testing</li>
                                         <li>Mobile Testing</li>  
                                         <li>Performance Testing</li>  
                                         <li>Security audit and testing</li>                                                                                                                              
                                         <li>Unit Testing</li>                                                                                                                                                                                                                                                                                                     
                                         </ul>
                                         </p>
										<a href="#">
											<span className="btn-effect blue-color">
												Read More
												<i className="fa fa-plus-circle ml-2" aria-hidden="true"></i>
											</span>
										</a>
									</div>
									<div className="fancy_img">
                                    <img src={require("assets/img/svg/services_svg/game-development.svg")} alt=""/>
                                    </div>
								</div>
							</div>
                            </Col>  

                            <Col lg="6" md="12">
                             <div className="fancy_service  wow fadeInUp  text-left" >
								<div className="bg_img">
									<img  src={require("assets/img/testi_m.png")} alt="fancybox"  className="topBottom" />
								</div>
								<div className="fancy_block">
									<div className="fancy-info">
										<h4 className="mt-4 mb-3 ">Independent Quality Control</h4>
										<p className=""> 
                                         <ul>
                                         <li>Compatibility Testing</li>    
                                         <li>Functional Testing</li>
                                         <li>Mobile Testing</li>  
                                         <li>Performance Testing</li>  
                                         <li>Security audit and testing</li>                                                                                                                              
                                         <li>Unit Testing</li>                                                                                                                                                                                                                                                                                                     
                                         </ul>
                                         </p>
										<a href="#">
											<span className="btn-effect blue-color">
												Read More
												<i className="fa fa-plus-circle ml-2" aria-hidden="true"></i>
											</span>
										</a>
									</div>
									<div className="fancy_img">
                                    <img src={require("assets/img/svg/services_svg/game-development.svg")} alt=""/>
                                    </div>
								</div>
							</div>
                            </Col>

                             <Col lg="6" md="12">
                             <div className="fancy_service  wow fadeInUp  text-left" >
								<div className="bg_img">
									<img  src={require("assets/img/testi_m.png")} alt="fancybox"  className="topBottom" />
								</div>
								<div className="fancy_block">
									<div className="fancy-info">
										<h4 className="mt-4 mb-3 ">IT Support Services</h4>
										<p className=""> 
                                         <ul>
                                         <li>Emergency Support</li>    
                                         <li>Network Troubleshooting</li>
                                         <li>PCI DSS Certification</li>  
                                         <li>Remote Installation</li>  
                                         <li>Server Management</li>                                                                                                                              
                                         <li>System Backup and Restore</li>                                                                                                                                                                                                                                                                                                     
                                         </ul>
                                         </p>
										<a href="#">
											<span className="btn-effect blue-color">
												Read More
												<i className="fa fa-plus-circle ml-2" aria-hidden="true"></i>
											</span>
										</a>
									</div>
									<div className="fancy_img">
                                    <img src={require("assets/img/svg/services_svg/support-services.svg")} alt=""/>
                                    </div>
								</div>
							</div>
                            </Col>    

                            <Col lg="6" md="12">
                             <div className="fancy_service  wow fadeInUp  text-left" >
								<div className="bg_img">
									<img  src={require("assets/img/testi_m.png")} alt="fancybox"  className="topBottom" />
								</div>
								<div className="fancy_block">
									<div className="fancy-info">
										<h4 className="mt-4 mb-3 ">Web Development</h4>
										<p className=""> 
                                         <ul>
                                         <li>Business websites</li>    
                                         <li>E-Learning</li>
                                         <li>Ecommerce Websites</li>  
                                         <li>Travel Booking Engine</li>  
                                         <li>Vertical Development</li>                                                                                                                                                                                                                                                                                                                                                                                                                                                                         
                                         </ul>
                                         </p>
										<a href="#">
											<span className="btn-effect blue-color">
												Read More
												<i className="fa fa-plus-circle ml-2" aria-hidden="true"></i>
											</span>
										</a>
									</div>
									<div className="fancy_img">
                                    <img src={require("assets/img/svg/services_svg/web-development.svg")} alt=""/>
                                    </div>
								</div>
							</div>
                            </Col>   

                             <Col lg="6" md="12">
                             <div className="fancy_service  wow fadeInUp  text-left" >
								<div className="bg_img">
									<img  src={require("assets/img/testi_m.png")} alt="fancybox"  className="topBottom" />
								</div>
								<div className="fancy_block">
									<div className="fancy-info">
										<h4 className="mt-4 mb-3 ">Mobile Apps Development</h4>
										<p className=""> 
                                         <ul>
                                         <li>Android Apps</li>    
                                         <li>iPad Apps</li>
                                         <li>iPhone Apps</li>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   
                                         </ul>
                                         </p>
										<a href="#">
											<span className="btn-effect blue-color">
												Read More
												<i className="fa fa-plus-circle ml-2" aria-hidden="true"></i>
											</span>
										</a>
									</div>
									<div className="fancy_img">
                                    <img src={require("assets/img/svg/services_svg/app-development.svg")} alt=""/>
                                    </div>
								</div>
							</div>
                            </Col>  

                            <Col lg="6" md="12">
                             <div className="fancy_service  wow fadeInUp  text-left" >
								<div className="bg_img">
									<img  src={require("assets/img/testi_m.png")} alt="fancybox"  className="topBottom" />
								</div>
								<div className="fancy_block">
									<div className="fancy-info">
										<h4 className="mt-4 mb-3 ">Web Application</h4>
										<p className=""> 
                                         <ul>
                                         <li>CMS applications</li>    
                                         <li>CRM Web Application</li>
                                         <li>Financial Web Application</li>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   
                                         </ul>
                                         </p>
										<a href="#">
											<span className="btn-effect blue-color">
												Read More
												<i className="fa fa-plus-circle ml-2" aria-hidden="true"></i>
											</span>
										</a>
									</div>
									<div className="fancy_img">
                                    <img src={require("assets/img/svg/services_svg/ux-design.svg")} alt=""/>
                                    </div>
								</div>
							</div>
                            </Col>  
                        </Row>
                    </Container>
                </div>
                
            </div>
        )
    }
}

export default ServicesPage
