import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/css/nucleo-icons.css";
import "assets/scss/blk-design-system-react.scss?v=1.1.0";
import "assets/demo/demo.css";

import Index from "./views/Index";
import IndexNavbar from "./components/Navbars/IndexNavbar"
import Footer from "./components/Footer/Footer.js";
import ServicesPage from "./views/IndexSections/ServicesPage";
import TechnologiesPage from "./views/IndexSections/TechnologiesPage";
import AboutusPage from "./views/IndexSections/AboutusPage";
import HirUs from "./views/IndexSections/HirUs";
import ScrollToTop from "./views/IndexSections/ScrollToTop";
import PrivacyPolicy from "./views/IndexSections/PrivacyPolicy"
import TAC from "./views/IndexSections/TAC"
import store from "./store/store"
import { Provider } from 'react-redux';
import FAQ from "./views/IndexSections/FAQ";
import Chat from "./views/IndexSections/Chat"
import PageNotFound from "./views/IndexSections/PageNotFound"
import TechnologiesDetails from "./views/IndexSections/TechnologiesDetails"



ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <IndexNavbar />
      <Switch>
        <Route exact path="/" render={props => <Index {...props} />} />
        {/* <Route
          path="/landing-page"
          render={props => <LandingPage {...props} />}
        /> */}

        <Route exact
          path="/contact-us"
          render={props => <HirUs {...props} />}
        />
        <Route exact
          path="/technologies"
          render={props => <TechnologiesPage {...props} />}
        />
        <Route exact
          path="/details/:id"
          render={props => <TechnologiesDetails {...props} />}
        />
        <Route exact
          path="/about-us"
          render={props => <AboutusPage {...props} />}
        />
        <Route exact
          path="/services"
          render={props => <ServicesPage {...props} />}
        />

        <Route exact
          path="/privacy-policy"
          render={props => <PrivacyPolicy {...props} />} />
        <Route exact
          path="/terms-and-conditions"
          render={props => <TAC {...props} />} />
        <Route exact
          path="/faq"
          render={props => <FAQ {...props} />} />
        <Route exact
          path="/404"
          render={props => <PageNotFound {...props} />} />
        <Redirect to="/404" />
      </Switch>
      <ScrollToTop />
      <Chat />
      <Footer />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
