import React, { useEffect, useState } from 'react'
import { Row, Col, Container } from 'reactstrap';
import "../../assets/fonts/themify-icons/themify-icons.css"
import { servicesList } from "../../action/index"
import { connect } from "react-redux"
import { LargeLoader } from 'comman/loader';
import WOW from 'wowjs';

function ServicesHome(props) {
    const wow = new WOW.WOW();
    wow.init();
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' });

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        props.dispatchList()

    }, [])


    useEffect(() => {

        if (props.listStatus === "SUCCESS") {
            setData(props.data.data)
            setLoading(false)
        }

    }, [props.listStatus, props.data])


    return (
        <div className="engineering-page">
            <div className="round-p-animation wow flipInY" data-wow-duration="1s" />
            <Container >
                <Row>
                    <Col lg="5" md="12">
                        <h2 className="section-title">Engineering <span> Services </span></h2>
                    </Col>
                    <Col lg="7" md="12"><p>Annexlogics offers highly scalable, customized and functional web design, web development, mobile app development and digital marketing solutions, and provides complete solution and support to your critical business IT requirements at very competitive prices.</p></Col>
                </Row>
                {loading ? <Row>
                    <LargeLoader style="mt-5" />
                </Row> :
                    <Row>
                        {data.map((val, index) => {
                            return (
                                <Col lg="3" md="6" key={index} className={index % 2 === 0 && 'sm-mt-5' || index % 2 === 1 && "md-mt-5"}>
                                    <div className="service-card item style-4">

                                        <div className="service-icons" dangerouslySetInnerHTML={{ __html: val.icon_code }} />
                                        <h5>{val.title}</h5>
                                        <p>{val.description}</p>
                                    </div>
                                </Col>
                            )
                        })}

                    </Row>
                }
            </Container>
        </div>
    )
}
const mapStateToProps = state => ({
    listStatus: state.servicesList.status,
    data: state.servicesList.message,
})

const mapDispatchToProps = dispatch => ({
    dispatchList: data => dispatch(servicesList(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ServicesHome);
