import React, { PureComponent, Component } from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import WOW from 'wowjs';
export default class AboutUsSlider extends PureComponent {
    // shouldComponentUpdate(nextProps, nextState) {
    // 	return false;
    // }
    componentDidMount() {
        const wow = new WOW.WOW();
        wow.init();
        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
    }
    render() {
        return (
            <div>
                <OwlCarousel autoplay={true} loop={true} mouseDrag={true} nav={false} className="owl-theme " responsive={{
                    0: {
                        items: 1,
                    },
                    450: {
                        items: 1,
                    },
                    600: {
                        items: 1,
                    },
                    768: {
                        items: 2,
                    },
                    1200: {
                        items: 2,
                    },
                    1600: {
                        items: 2,
                    },
                }} dots={false}  >

                    <div className="aboutushome-owl text-center mx-3 my-3 wow flipInY" data-wow-duration="1s">
                        <div className="iteems">
                            <div className="aboutushome-owl-icon">
                                <img src={require("assets/img/philosophy.png")} className="aboutushome-owl-img" alt="" />
                            </div>
                            <div className="aboutushome-owl-heading">
                                <h5>Philosophy</h5>
                            </div>
                            <div className="aboutushome-owl-details">
                                <p>We believe in philosophy of success leads success. When we work with startups, small or medium business where they are planning a product launch, we help them in all the way possible to meet their goals. We help them understand the process, drive software development process as per their wish. </p>
                            </div>
                        </div>
                    </div>



                    <div className="aboutushome-owl text-center mx-3 my-3 wow flipInY" data-wow-duration="1s">
                        <div className="iteems">
                            <div className="aboutushome-owl-icon">
                                <img src={require("assets/img/history.png")} className="aboutushome-owl-img" alt="" />
                            </div>
                            <div className="aboutushome-owl-heading">
                                <h5>Culture</h5>
                            </div>
                            <div className="aboutushome-owl-details">
                                <p>A culture based on trust, transparency, autonomy, and respect helps us
                                    build strong long term relationships with all our stakeholders.
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                </p>
                            </div>

                        </div>
                    </div>




                    <div className="aboutushome-owl text-center mx-3 my-3 wow flipInY" data-wow-duration="1s">
                        <div className="iteems">
                            <div className="aboutushome-owl-icon">
                                <img src={require("assets/img/triqueta.png")} className="aboutushome-owl-img" alt="" />
                            </div>
                            <div className="aboutushome-owl-heading">
                                <h5>Belief/Vision</h5>
                            </div>
                            <div className="aboutushome-owl-details">
                                <p>We believe in building long term win-win relationships with our customers and our
                                    employees, instead of short term transaction oriented interactions.
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                </p>
                            </div>

                        </div>
                    </div>


                    <div className="aboutushome-owl text-center mx-3 my-3 ">
                        <div className="iteems">
                            <div className="aboutushome-owl-icon">
                                <img src={require("assets/img/values.png")} className="aboutushome-owl-img" alt="" />
                            </div>
                            <div className="aboutushome-owl-heading">
                                <h5>Values and principles</h5>
                            </div>
                            <div className="aboutushome-owl-details">
                                <p>We focus on principles of full disclosure, fairness, equity, transparency and
                                    accountability in the various aspects of its functioning, leading to protection
                                    of the stakeholders’ interest and an enduring relationship with stakeholders.
                                    <br />
                                    <br />

                                </p>
                            </div>

                        </div>
                    </div>
                </OwlCarousel>
            </div>
        )
    }
}
