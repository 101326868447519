export default function demo(state = [], action) {
    switch (action.type) {
        case "DEMO_REDUCER":
            return {
                ...state,
                 items:"DEMO" 
            }
        default:
            return state;
    }

}