import { takeEvery, call, put } from "redux-saga/effects";
import Axios from "axios";
import { TEAM_LIST } from "../action/ActionType"
export default function* teamList() {
    yield takeEvery(TEAM_LIST, authorize);
}

export async function httpCall(method, url, data, headers = {}) {

    return await Axios({
        method,
        url,
        data,
        headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "application/json",
            ...headers
        }
    });
}
export function* authorize(action) {
    //   return ;
    try {
        const getApiData = yield call(
            httpCall,
            "get",
            "https://api.annexlogics.com/api/dashboardannexlogics/team_list",
            {

            }
        );
        const resultData = getApiData

        if (resultData.status === 200) {
            yield put({ type: "TEAM_LIST_SUCCESS", resultData: resultData.data });
        } else {
            yield put({ type: "TEAM_LIST_FAILED", resultData: resultData.data });
        }
    } catch (error) {

        yield put({ type: "TEAM_LIST_FAILED", resultData: "Network Error! Please try again after sometime" });
    }
}