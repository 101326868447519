import React, { useEffect, useState } from 'react'
import { Container, Row, Col, } from 'reactstrap'
import { Helmet } from "react-helmet";
import { connect } from "react-redux"
import { caseStudyDetail } from "../../action/index"
import { LargeLoader } from "../../comman/loader"

function TechnologiesDetails(props) {
    const [data, setData] = useState({})
    
    useEffect(() => {
        window.scrollTo({ top: 0,left: 0,behavior: 'auto'});
        const id = props.match.params.id;
        props.dispatchList(id)
    }, [])
    useEffect(() => {
        if (props.listStatus === "SUCCESS") {
            setData(props.data)
        }
    }, [props.listStatus, props.data])
    
        return (
            <div className="casedetail-bg-white">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Mobile and Web App Development Services</title>
                    <meta name="description" content="AnnexLogics has professional and dedicated team to design and develop your online business. Our team always believe in innovation and deliver quality work on time. " />

                </Helmet>
                <div className="casedetail-header" >
                    <Container>
                        <Row>

                            <Col md="12">
                                <h1 className="casedetail-title">Case Study</h1>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item-active">Technologies</li>
                                </ol>
                            </Col>
                        </Row>
                    </Container>
                    
                </div>
                <div className="casedetail-content">
                    <Container className="casedetail-section1-card2">
                        {props.listStatus === 'LOADING' ?  <Row><LargeLoader style="mt-5" /> </Row>:
                            <div>
                                <Row className="casedetail-page-head">
                                    <Col lg="10" md="12">
                                        <div className="mb-3 casedetail-title">
                                            <h6>{data.subtitle}</h6>
                                            <h2>{data.title}</h2>
                                        </div>                                       
                                    </Col>
                                    <Col lg="2" md="12">
                                        <img src={data.photo} className="img-fluid casedetail-img" alt="" />
                                    </Col>
                                    
                                </Row>
                                
                                <Row>
                                    <Col lg="12" md="12">
                                        <div className="casedetail-title-details" dangerouslySetInnerHTML={{__html: data.description}} />
                                    </Col>
                                </Row>
                            </div>
                            
                        }
                    </Container>
                </div>
            </div>

        )
    }



const mapStateToProps = state => ({
    listStatus: state.casestudydetails.status,
    data: state.casestudydetails.message,
})

const mapDispatchToProps = dispatch => ({
    dispatchList: data => dispatch(caseStudyDetail(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TechnologiesDetails)


