import React, { useEffect, useState } from 'react'
import {
    Row,
    Col,
    Container,
} from "reactstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "../../assets/fonts/flaticon.css"
import { clientList } from "../../action/index"
import { connect } from 'react-redux'
import "react-tabs/style/react-tabs.css";
import { LargeLoader } from 'comman/loader';
import 'react-image-lightbox/style.css';
import OwlCarousel from 'react-owl-carousel';
let images = []
function WorkHere(props) {
    const [clientValues, setClientValues] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        props.dispatchList()

    }, [])

    useEffect(() => {
        if (props.clientStatus === "SUCCESS") {
            setClientValues(props.clientData.data)
            setLoading(false)
            const filterData = props.clientData.data.includes(val => val.category === "1")
            {
                props.clientData.data.map(val => {
                    images.push(val.screenshot)
                })
            }
        }
    }, [props.clientStatus,])
    useEffect(() => {
    })



    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const options1 = {
        loop: true,
        margin: 0,
        dots: true,
        autoplay: false,
        autoplayTimeout: 4000,
        smartSpeed: 1000,
        mouseDrag: true,
        touchDrag: false,
        center: true,
        items: 1,
        nav: true,
        lazyLoad: true,
        onTranslate: callback
    }

    const options2 = {
        loop: true,
        margin: 0,
        dots: true,
        autoplay: false,
        autoplayTimeout: 4000,
        smartSpeed: 1000,
        mouseDrag: true,
        touchDrag: false,
        center: true,
        items: 1,
        lazyLoad: true,
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 1,
            },
            1000: {
                items: 1,
            },
        },

        onTranslate: callback2

    }

    function callback(event) {
        var page = event.page.index;

        var x = document.getElementById("image_slider").querySelectorAll('.owl-dot');
        x[page].click();

    }

    function callback2(event) {
        var page = event.page.index;

        var x = document.getElementById("text_slider").querySelectorAll('.owl-dot');
        x[page].click();

    }




    return (
        <div className="workhere-main">
            <Container>
                {loading ? <Row >
                    <LargeLoader style="mt-5" />
                </Row> :

                    <Row>

                        <Col lg="12" md="12">
                            <Tabs>
                                <TabList>
                                    <Tab>
                                        WEBSITES
                                    </Tab>
                                    <Tab className="mobile-tab-btn react-tabs__tab">
                                        MOBILE-APP
                                    </Tab>
                                </TabList>
                                <TabPanel>
                                    <Row className="align-items-center">
                                        <Col lg="6" md="12" className="testimonial-text">
                                            <div className="home_screen_slide_main" id="text_slider">
                                                <div className="home_text_slide">
                                                    <OwlCarousel {...options1}>
                                                        {clientValues.map((val, index) => {
                                                            if (val.category == 2) {
                                                                return (
                                                                    <div className="item" key={index}>
                                                                        <h1 onClick={() => openInNewTab(val.website)} className="testimonial-title">{val.project_name}</h1>
                                                                        <div className="space-10"></div>
                                                                        <p className="testimonial-p">{val.description}</p>
                                                                        <div className="space-50"></div>

                                                                    </div>
                                                                )
                                                            }
                                                        })}
                                                    </OwlCarousel>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg="6" md="12" className="testimonial-text desktop_sl">
                                            <div className="home_screen_slide" id="image_slider">
                                                <div className="single_screen_slide wow fadeInRight">
                                                    <OwlCarousel {...options2} >
                                                        {clientValues.map((val, index) => {
                                                            if (val.category == 2) {
                                                                return (
                                                                    <div className="item" key={index}><img className="owl-lazy" data-src={val.label} data-src-retina={val.label} alt="" /></div>
                                                                )
                                                            }
                                                        })}
                                                    </OwlCarousel>
                                                </div>
                                            </div>

                                        </Col>
                                    </Row>
                                </TabPanel>

                                <TabPanel>
                                    <Row className="align-items-center">
                                        <Col lg="6" md="12" className="testimonial-text">
                                            <div className="home_screen_slide" id="image_slider">
                                                <div className="single_screen_slide wow fadeInRight mobile_app_layout">
                                                    <OwlCarousel {...options2} >
                                                        {clientValues.map((val, index) => {
                                                            if (val.category == 1) {
                                                                return (
                                                                    <div className="item" key={index}><img className="owl-lazy" data-src={val.label} data-src-retina={val.label} alt="" /></div>
                                                                )
                                                            }
                                                        })}
                                                    </OwlCarousel>
                                                </div>
                                            </div>

                                        </Col>

                                        <Col lg="6" md="12" className="testimonial-text">
                                            <div className="home_screen_slide_main" id="text_slider">
                                                <div className="home_text_slide">
                                                    <OwlCarousel {...options1}>
                                                        {clientValues.map((val, index) => {
                                                            if (val.category == 1) {
                                                                return (
                                                                    <div className="item" key={index}>
                                                                        <h1 onClick={() => openInNewTab(val.website)} className="testimonial-title">{val.project_name}</h1>
                                                                        <div className="space-10"></div>
                                                                        <p className="testimonial-p">{val.description}</p>
                                                                        <div className="space-50"></div>

                                                                    </div>
                                                                )
                                                            }
                                                        })}
                                                    </OwlCarousel>
                                                </div>
                                            </div>
                                        </Col>

                                    </Row>
                                </TabPanel>
                            </Tabs>
                        </Col>

                    </Row>
                }
            </Container>

        </div>
    )
}

const mapStateToProps = state => ({
    clientStatus: state.clientList.status,
    clientData: state.clientList.message,

})

const mapDispatchToProps = dispatch => ({
    dispatchList: data => dispatch(clientList(data)),

})

export default connect(mapStateToProps, mapDispatchToProps)(WorkHere);
