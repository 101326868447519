import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Container, Row, Col, Button } from 'reactstrap'
import AboutuspageSlider from './Aboutuspage_Slider';
import AboutusTestimonial from './Aboutus_Testimonial';
import { Helmet } from "react-helmet";
import Count from './Count';
import { ClientLogo } from './ClientLogo';
import {withRouter,Link} from "react-router-dom";
import { clientList, getTeam, getCase, TestimonialList } from "../../action/index"
import Team from './Team';
function AboutusPage(props) {
    const [loading, setLoading] = useState(true)
    const [clientValues, setClientValues] = useState([])
    const [teamLoad, setTeamLoad] = useState(true)
    const [teamData, setTeamData] = useState([])
    const [testimonialLoad, setTestimonialLoad] = useState(true)
    const [testimonialData, setTestimonialData] = useState(true)
    const [caseLoad, setCaseLoad] = useState(true)
    const [caseData, setCaseData] = useState([])
    useEffect(() => {
        window.scrollTo({ top: 0,left: 0,behavior: 'auto'});
        setLoading(false)
        props.dispatchList()
        props.dispatchTeam()
        props.dispatchCase()
        props.dispatchTestimonial()
    }, [])
    useEffect(() => {
        if (props.clientStatus === "SUCCESS") {
            setClientValues(props.clientData.logoArr)
        }
        if (props.teamStatus === "SUCCESS") {
            setTeamLoad(false)
            setTeamData(props.teamData.data)
        }
        if (props.testimonialStatus === "SUCCESS") {
            setTestimonialLoad(false)
            setTestimonialData(props.testimonialData.data)
        }
        if (props.caseStatus === "SUCCESS") {
            setCaseLoad(false)
            setCaseData(props.caseData.data)
        }

    }, [props.clientStatus, props.teamStatus, props.testimonialStatus,props.caseStatus])

    return (
        <div className="aboutuspage-bg-white">      
            <Helmet>
                <meta charSet="utf-8" />
                <title>About Us | AnnexLogics</title>
                <meta name="description" content="AnnexLogics is a team of professionals who believe in creating business value for their clients through in-depth research, analysis, formation and implementation." />

            </Helmet>
            <div className="aboutuspage-header" >
            <div className="ani_right">
              <svg xmlns="http://www.w3.org/2000/svg" link="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1024 1024">
                        <path id="followPath" d="M394.1-214.9c-49.7,89.4,114.4,192.8,175.5,475.1c13,60.1,85.4,424-98.1,552.6 c-95.7,67-267.2,74.5-346.3-22.1c-70.8-86.5-49-233.9,19.2-305.2c102.4-107,353.9-89.1,593.2,96.5c139.6,107,294.1,258.4,415,468.6 c19.2,33.5,36.6,66.6,52.3,99.3c13,8.6,34,19.5,53.3,13.2c148-48.6,165.1-1094.5-338.5-1374.8C723.7-320.8,449-313.8,394.1-214.9z"></path>
                        <path id="dashedPath" d="M394.1-214.9c-49.7,89.4,114.4,192.8,175.5,475.1c13,60.1,85.4,424-98.1,552.6 c-95.7,67-267.2,74.5-346.3-22.1c-70.8-86.5-49-233.9,19.2-305.2c102.4-107,353.9-89.1,593.2,96.5c139.6,107,294.1,258.4,415,468.6 c19.2,33.5,36.6,66.6,52.3,99.3c13,8.6,34,19.5,53.3,13.2c148-48.6,165.1-1094.5-338.5-1374.8C723.7-320.8,449-313.8,394.1-214.9z"></path>
                        <path id="airplain" d="M8.04 84L92 48 8.04 12 8 40l60 8-60 8z">
                            <animateMotion href="#airplain" dur="6s" fill="freeze" repeatCount="indefinite" rotate="auto">
                                <mpath href="#followPath"></mpath>
                            </animateMotion>
                        </path>
                    </svg>
                 </div>
                 <div class="ani_inner_banner"> 
                  <div class="ani-cube ani-cube-3">
                        <div className="cube-face face_front" />
                        <div className="cube-face face_right" />
                        <div className="cube-face face_left" />
                        <div className="cube-face face_top" />
                        <div className="cube-face face_bottom" />
                        <div className="cube-face face_back" />
                    </div>
                  </div>
                  <div className="ani ani-5">
                  <div className="moving-square-frame" />
                  <div className="ani-moving-square" />
                  </div>
                <Container>
                    <Row>
                        <Col md="12">
                            <h1 className="aboutuspage-title">About Us</h1>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                <li className="breadcrumb-item-active">About Us</li>
                            </ol>
                        </Col>
                    </Row>
                </Container>
                
            </div>
            <div className="aboutuspage-conten">
                <Container>
                    <Row className="align-items-center">
                        <Col lg="6" md="12" className="order-lg-12">
                            <Row>
                                <Col md="12">
                                    <img src={require("assets/img/about_us.svg")} className="img-fluid about-us-img topBottom" alt="" />
                                </Col>                                
                            </Row>
                        </Col>
                        <Col lg="6" md="12" className="order-lg-1 md-mt-5">
                            <div className="mb-3 aboutus-title">
                                <h6>About us</h6>
                                <h2>We help innovators <span>implement ideas.</span></h2>
                            </div>
                            <p className="aboutus-title-details">
                                AnnexLogic System Pvt. Ltd. is a leading comprehensive business software product development
                                services provider. We partner with both start-ups and established businesses globally in
                                their idea-to-implementation journey. We are a team of professionals who believe in creating
                                business value for our clients through in-depth research, analysis, formation and
                                implementation. Our customers are spread across the globe including US, Canada, Australia,
                                UK, Europe and Asia-Pacific region.
                            </p>
                            <ul className="aboutus-customer-list my-3">
                                <li>Resource Outsourcing</li>
                                <li>Business Process Automation</li>
                                <li>New Product Development</li>
                                <li>Mobile App Development</li>
                            </ul>
                            <Link to="/service" className="heading-us">
                                <Button className="aboutus-title-button">Learn More</Button>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="aboutus-success-stories">
                <Container>
                    <Row className="text-center">
                        <Col lg="8" md="12" className="ml-auto mr-auto">
                            <div className="sucess-stories">
                                <h6>SUCCESS STORIES</h6>
                                <h2>Our Case <span>Study</span></h2>
                                <p>We've helped businesses grow online since 2012 and have a number of case studies to show how we did it.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <AboutuspageSlider loading={caseLoad} data={caseData} />
            <Container>
            <ClientLogo data={clientValues} />
            </Container>
            <div className="aboutus-services">
                <div className="pattern-3">
                    <img src={require("assets/img/03.webp")} className="img-fluid img-rotateme" alt="" />
                </div>
                <Container>
                    <Row>
                        <Col lg="8" md="12" className="ml-auto mr-auto">
                            <div className="aboutus-services-title">
                                <h2>We Are Best Consultancy to Implement <span> Your Ideas! </span></h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="4" md="6">
                            <div className="aboutus-services-items">
                                <img src={require("assets/img/svg/about_svg/idea.svg")} className="img-center" alt="" />
                                <h5>IDEA</h5>
                                <p>before start let’s think. Not in hurry, just seat relax and
                                    give us time to provide a better idea for your business.</p>
                            </div>
                        </Col>
                        <Col lg="4" md="6" className="sm-mt-3">
                            <div className="aboutus-services-items">
                            <img src={require("assets/img/svg/about_svg/innovation.svg")} className="img-center" alt="" />
                                <h5>INNOVATION</h5>
                                <p>An innovative innovation always fresh your mind after a long journey. A simple
                                    idea with better implementation can lead innovation.</p>
                            </div>
                        </Col>
                        <Col lg="4" md="6" className="sm-mt-3">
                            <div className="aboutus-services-items">
                            <img src={require("assets/img/svg/about_svg/implementation.svg")} className="img-center" alt="" />
                                <h5>IMPLEMENTATION</h5>
                                <p>A bad idea also can be implemented with better way. You are better not best,
                                    if you only know cook not how to serve.</p>
                            </div>
                        </Col>
                        
                    </Row>
                </Container>
            </div>
            <Team loading={teamLoad} data={teamData} />
            <AboutusTestimonial loading={testimonialLoad} data={testimonialData} />
            
        </div>
    )
}


const mapStateToProps = state => ({
    clientStatus: state.clientList.status,
    clientData: state.clientList.message,
    teamStatus: state.teamList.status,
    teamData: state.teamList.message,
    testimonialStatus: state.testimonialList.status,
    testimonialData: state.testimonialList.message,
    caseStatus: state.caseList.status,
    caseData: state.caseList.message,
})

const mapDispatchToProps = dispatch => ({
    dispatchList: data => dispatch(clientList(data)),
    dispatchTeam: data => dispatch(getTeam(data)),
    dispatchCase: data => dispatch(getCase(data)),
    dispatchTestimonial: data => dispatch(TestimonialList(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(AboutusPage)
