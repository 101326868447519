function  casestudydetails(state ={status:null , message:""  },action){
    switch(action.type){

      case "CASESTUDY_DETAIL_LOAD":
      return {
        ...state,
        status: "LOADING",
        loading: true
      }


      case "CASESTUDY_DETAIL_SUCCESS":
      return {
        ...state,
        status: "SUCCESS",
        message:action.resultData,
        loading: false
      }
      case "CASESTUDYDETAIL_DETAIL_FAILED":
      return {
        ...state,
        status: "FAILED",
        message:action.resultData,
        loading: false
      };

    default:
      return state;
    }
}

export default casestudydetails;