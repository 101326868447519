import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'reactstrap'
import ClientLogo from './ClientLogo';
import { clientList } from "../../action/index"
import { connect } from 'react-redux'
import { LargeLoader } from "../../comman/loader"
function News(props) {
    const [clientValues, setClientValues] = useState([])
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        props.dispatchList()

    }, [])
    useEffect(() => {
        if (props.clientStatus === "SUCCESS") {
            setClientValues(props.clientData.logoArr)
            setLoading(false)
        }

    }, [props.clientStatus,])
    return (
        <div className="news-main">
            <div className="iq-waves">
                <div className="waves wave-1"></div>
                <div className="waves wave-2"></div>
                <div className="waves wave-3"></div>
            </div>
            <Container>
                {loading ? <Row>
                    <LargeLoader style="mt-5" />
                </Row> :
                    <ClientLogo data={clientValues} style="news-client" />
                }
            </Container>
        </div>
    )
}

const mapStateToProps = state => ({
    clientStatus: state.clientList.status,
    clientData: state.clientList.message,

})

const mapDispatchToProps = dispatch => ({
    dispatchList: data => dispatch(clientList(data)),

})

export default connect(mapStateToProps, mapDispatchToProps)(News);
