import React, { Component } from 'react'
 
import AboutUsSlider from "./AboutUsSlider"
import {
    Row,
    Col,
    Container,
} from "reactstrap";
class AboutUsHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customer_counter: 0,
            project_done: 0,
            review_counter: 0,
            retention_counter: 0,
            responsive:{
                0: {
                    items: 1,
                },
                450: {
                    items: 1,
                },
                600: {
                    items: 1,
                },
                768: {
                    items: 2,
                },
                1200: {
                    items: 2,
                },
                1600: {
                    items: 2,
                },
            },
        }
    }
    customer() {
        if (this.state.customer_counter < 230) {
            this.setState(prevState => ({
                customer_counter: prevState.customer_counter + 1
            }));
        }
    }
    review() {
        if (this.state.review_counter < 100) {
            this.setState(prevState => ({
                review_counter: prevState.review_counter + 1
            }));
        }
    }
    project() {
        if (this.state.project_done < 250) {
            this.setState(prevState => ({
                project_done: prevState.project_done + 1
            }));
        }
    }
    retention(){
        if (this.state.retention_counter < 99) {
            this.setState(prevState => ({
                retention_counter: prevState.retention_counter + 1
            }));
        }
    }
    componentDidMount() {
        this.interval = setInterval(() => this.customer(), 200);
        this.interval = setInterval(() => this.retention(), 453);
        this.interval = setInterval(() => this.project(), 190);
        this.interval = setInterval(() => this.review(), 453);    
      }
      componentWillUnmount() {
        clearInterval(this.interval);
      }
    
    render() {
        return (
            <>
                <div className="abouthome-main ">
                <div className="animationnew-shap iq-fadebounce">
                <div className="shap-bg"></div>
                <div className="shap-bg-to"></div>
                <div className="shap-bg-three"></div>
                </div>
                    <div className="about-bg">
                        <img src={require("assets/img/03.webp")} className="img-fluid img-rotateme" alt=""/>
                    </div>
                    <Container>
                        <Row>
                            <Col lg="12" md="12">
                                <div className="ml-auto mr-auto col-md-12 col-lg-8">
                                    <div className="abouthome-title_n">
                                        <h2>Why Choose Our Agency Over <span>Other Agencies? </span></h2>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="5" md="12">
                                <div className="abouthome-title">
                                    <p>We believe in a win-win solution by establishing long term relationships with our valuable clients. We work in a broad domain including mobile/web design and development, search engine optimization, internet marketing solutions, e-commerce solutions, web hosting, salesforce, end to end IT consulting services and the list goes on. Our experts would love to serve you with our innovative solutions and 24*7 customer support.</p>
                                    <br></br>
                                    <b>Choosing us means: - </b><br></br>
                                    <ul>
                                        <li>Track record of delivering projects on time.</li>
                                        <li>Dynamic development methodology to make a successful online business.</li>
                                        <li>We always include a complete estimated breakdown of time and price required to complete a project.</li>
                                        <li>Our average hold time for technical support is exceptionally fast.</li>
                                        <li>We Create Experiences.</li>
                                        <li>We Ask, Listen, Understand and Execute.</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col lg="7" md="12" className="md-mt-5">
                                <div className="home-owl-carousel">
                                    <AboutUsSlider />
                                
                                </div>
                            </Col>
                        </Row>
                        
                    </Container>
                </div>
                
            </>
        )
    }
}

export default AboutUsHome;
