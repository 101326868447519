import { takeEvery, call, put } from "redux-saga/effects";
import Axios from "axios";
import { ADD_LEAD } from "../action/ActionType"
export default function* lead() {
    yield takeEvery(ADD_LEAD, authorize);
}

export async function httpCall(method, url, data, headers = {}) {

    return await Axios({
        method,
        url,
        data,
        headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "application/json",
            ...headers
        }
    });
}
export function* authorize(action) {
    let data = action.payload
    console.log(data , "line number 23");
    //   return ;
    try {
        const getApiData = yield call(
            httpCall,
            "post",
            "http://dashboardannexlogics.we4php.com/api/dashboardannexlogics/lead",
            {
                "name": data.name,
                "email": data.email,
                "message": data.message,
                "mobile": data.phone
            }
        );
        const resultData = getApiData

        if (resultData.status === 200) {
            yield put({ type: "LEAD_SUCCESS", resultData: resultData.data });
        } else {
            yield put({ type: "LEAD_FAILED", resultData: resultData.data });
        }
    } catch (error) {

        yield put({ type: "LEAD_FAILED", resultData: "Network Error! Please try again after sometime" });
    }
}