import { takeEvery, call, put } from "redux-saga/effects";
import Axios from "axios";
import {CASE_STUDY_DETAIL} from "../action/ActionType"
export default function* casedetail() {
    yield takeEvery(CASE_STUDY_DETAIL, authorize);
}

export async function httpCall(method, url, data, headers = {}) {

    return await Axios({
        method,
        url,
        data,
        headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "application/json",
            ...headers
        }
    });
}

export function* authorize(action) {
    const slug = action.payload;

    try {
        yield put({ type: "CASESTUDY_DETAIL_LOAD"});
        const getApiData = yield call(
            httpCall,
            "get",
            "https://api.annexlogics.com/api/dashboardannexlogics/castStudy_list/details/"+slug,
            {

            }
        );
        const resultData = getApiData

        if (resultData.status === 200) {
            yield put({ type: "CASESTUDY_DETAIL_SUCCESS", resultData: resultData.data.data });
        } else {
            yield put({ type: "CASESTUDYDETAIL_DETAIL_FAILED", resultData: resultData.data.data });
        }
    } catch (error) {

        yield put({ type: "CASESTUDYDETAIL_DETAIL_FAILED", resultData: "Network Error! Please try again after sometime" });
    }
}